@keyframes close {
  0% {
    opacity: 1;
    display: flex;
  }
  100% {
    opacity: 0;
    display: none;
  }
}

html {
  background-color: #1c1c1c;
  font-size: calc(100vw / 1440 * 10);
}

.root {
  width: 100%;
  min-height: 100vh;
  background-color: #1c1c1c;
  font-size: calc(100vw / 1440 * 10);
}

.page {
  min-width: 320px;
  margin: 0 auto;
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Montserrat", Arial, sans-serif;

  font-weight: 500;
  font-style: normal;
  color: #000000;

  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

::-webkit-scrollbar {
  width: 12px; /* Ширина вертикального скроллбара */
  height: 12px; /* Высота горизонтального скроллбара */
}

/* Стили для трека (фона) скроллбара */
::-webkit-scrollbar-track {
  background: #232323; /* Цвет фона */
  /* border-radius: 10px;  */
}

::-webkit-scrollbar-thumb {
  background: #555; /* Цвет ползунка */
  border-radius: 10px; /* Скругление углов */
}

/* Стили для ползунка при наведении */
::-webkit-scrollbar-thumb:hover {
  background: #3f3f3f; /* Цвет ползунка при наведении */
}

/* Button */

.button {
  padding: 1.5rem 3rem;
  color: #000000;
  width: fit-content;
  background-color: #fff;
  height: fit-content;
  border: 0;
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 2.2rem;
  text-align: right;
  border-radius: 3rem;
  cursor: pointer;
  z-index: 2;
  text-align: center;
  text-decoration: none;
  transition: all 0.2s linear;
}

.button:hover {
  color: #e6007e;
}

.button_disabled {
  background-color: transparent;
  border: 1px solid #555;
  color: #555;
  cursor: default;
}

.button_disabled:hover {
  color: #555;
}

.button_type_glass {
  border: 2px solid #fff;
  background-color: rgba(45, 45, 45, 0.4);
  backdrop-filter: blur(10px);
  color: #fff;
}

.button_type_glass:hover {
  border-color: #e6007e;
  color: rgba(255, 255, 255, 0.4);
}

.button_type_glasses {
  padding: 1rem 3rem;
  margin-top: 1.2rem;
  color: #fff;
  position: relative;
  width: fit-content;
  height: fit-content;
  background-color: #2d2d2d66;
  backdrop-filter: blur(15px);
  border-radius: 2.4rem;
  box-sizing: border-box;
  z-index: 1;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 2.2rem;
  overflow: hidden;
}

.button_type_glasses:hover {
  color: #fff;
}

.button_type_glasses::before,
.button_type_glasses::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 2.4rem;
  padding: 0.1rem;
  z-index: -1;
  transition: opacity 0.4s ease; /* Анимация для изменения прозрачности */
}

.button_type_glasses::before {
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.16) 0%,
    rgba(102, 102, 102, 0.16) 100%
  );
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
  opacity: 1; /* Изначально видим */
}

.button_type_glasses::after {
  background: linear-gradient(
    90deg,
    rgba(102, 102, 102, 0.16) 0%,
    rgba(255, 255, 255, 0.16) 100%
  );
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
  opacity: 0; /* Изначально скрыт */
}

.button_type_glasses:hover::before {
  opacity: 0; /* При наведении скрываем ::before */
}

.button_type_glasses:hover::after {
  opacity: 1; /* При наведении показываем ::after */
}
/* Section-title */

.section-title {
  padding: 0 6rem;
  width: 100%;
  max-width: 144rem;
  margin: 4.6rem 0;
  font-size: 4.5rem;
  font-weight: 500;
  line-height: 5.5rem;
  text-align: left;
  color: #fff;
  box-sizing: border-box;
}

.section-title_disactive {
  animation: close 1s forwards;
}

.section-title_type_team {
  width: 109.6rem;
  padding: 0;
}

/* Radio */

.radio {
  position: relative;
  padding: 1.8rem 0;
  width: 100%;
  display: inline-flex;
  align-items: center;
  gap: 1.2rem;
  color: #fff;
  font-size: 2.4rem;
  font-weight: 400;
  line-height: 2.9rem;
  text-align: left;
  cursor: pointer;
  border-bottom: 0.2rem solid rgba(152, 152, 152, 1);
  transition: all 0.2s linear;
}

.radio::before {
  width: 0;
  content: "";
  position: absolute;
  bottom: -0.2rem;
  width: 0%;
  height: 0.2rem;
  background: rgba(230, 0, 126, 1);
  transition: all 0.5s ease-out;
}

.radio:hover::before {
  width: 100%;
}

.radio__input {
  display: none;
}

.radio__button {
  min-width: 2.4rem;
  max-width: 2.4rem;
  height: 2.4rem;
  border: 0.15rem solid rgba(152, 152, 152, 1);
  border-radius: 50%;
  box-sizing: border-box;
  padding: 0.2rem;
  transition: all 0.2s linear;
}

.radio__button_active {
  border: 0.15rem solid #fff;
}

.radio__button::after {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  background: rgba(152, 152, 152, 1);
  border-radius: 50%;
  box-sizing: border-box;

  transform: scale(0);
  transition: transform 0.1s linear;
}

.radio__button_active::after {
  background: #fff;
}

.radio__input:checked + .radio__button::after {
  transform: scale(1);
}

@media (min-width: 1441px) {
  html {
    font-size: 10px;
  }

  .button {
    padding: 15px 30px;
    font-size: 18px;
    line-height: 22px;
    border-radius: 30px;
  }

  .button_type_glasses {
    padding: 15px 30px;
    margin-top: 12px;
    border-radius: 24px;
    font-size: 18px;
    line-height: 21.94px;
  }

  .button_type_glasses::before,
  .button_type_glasses::after {
    border-radius: 24px;
    padding: 1px;
  }

  .section-title {
    width: 1440px;
    padding: 0 60px;
    margin: 46px 0;
    font-size: 45px;
    line-height: 55px;
  }

  .section-title_type_team {
    padding: 0;
    width: 1096px;
  }

  .radio {
    padding: 18px 0;
    gap: 12px;
    font-size: 24px;
    line-height: 29px;
    border-bottom: 2px solid rgba(152, 152, 152, 1);
  }

  .radio::before {
    bottom: -2px;
    height: 2px;
  }

  .radio__button {
    min-width: 24px;
    max-width: 24pxm;
    height: 24px;
    border: 1.5px solid rgba(152, 152, 152, 1);
    padding: 2px;
  }

  .radio__button_active {
    border: 1.5px solid #fff;
  }
}

@media (max-height: 700px) {
  html {
    font-size: calc(100vw / 1440 * 8.5);
  }
}

@media (max-width: 1024px) {
  html {
    font-size: calc(100vw / 768 * 10);
  }

  .button {
    padding: 1.2rem 2.4rem;
    font-size: 1.4rem;
    line-height: 1.7rem;
  }

  .button_type_glasses {
    padding: 1.5rem 3rem;
    margin-top: 12px;
    border-radius: 2.4rem;
    font-size: 1.8rem;
    line-height: 2.2rem;
  }

  .section-title {
    padding: 0 2.4rem;
    margin: 2.4rem 0;
    font-size: 3.2rem;
    line-height: 3.9rem;
  }

  .section-title_type_scheme {
    text-align: center;
  }

  .section-title_type_team {
    padding: 0 6rem;
    width: 72rem;
  }

  .radio {
    font-size: 1.6rem;
    line-height: 1.95rem;
  }
}

@media (max-width: 576px) {
  html {
    font-size: calc(100vw / 450 * 10);
  }

  .section-title {
    padding: 0 1.2rem;
    margin: 2.4rem 0;
    font-size: 2.4rem;
    line-height: 2.9rem;
    text-align: center;
  }

  .button_type_glasses {
    width: 100%;
  }

  .section-title_type_team {
    width: 33.6rem;
  }
}

@media (max-width: 449px) {
  html {
    font-size: calc(100vw / 360 * 10);
  }
}
