.footer {
  width: 100%;
  background-color: rgba(45, 45, 45, 0.4);
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.footer__container {
  padding: 6rem;
  box-sizing: border-box;
  width: 100%;
  max-width: 144rem;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 4.2rem;
}

.footer__column {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.footer__column_type_logo {
  order: 0;
}

.footer__column_type_services {
  order: 2;
}

.footer__column_type_gallery {
  order: 1;
}

.footer__column_type_company {
  order: 3;
}

.footer__column_type_contacts {
  order: 4;
}

.footer__column_type_socials {
  order: 5;
}

.footer__image {
  width: 16rem;
  height: 4.4rem;
}

.footer__policy {
  margin: 0;
  color: rgba(152, 152, 152, 1);
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.7rem;
}

.footer__policy-link {
  color: rgba(0, 140, 255, 1);
  cursor: pointer;
  text-decoration: none;
}

.footer__title {
  margin: 0;
  color: rgba(152, 152, 152, 1);
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 2.9rem;
  text-align: left;
}

.footer__list {
  padding: 0;
  box-sizing: border-box;
  margin: 0;
  width: 100%;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  transition: all 0.2s linear;
}

.footer__list_type_small {
  display: none;
}

.footer__element {
  width: 100%;
}

.footer__link {
  margin: 0;
  color: #fff;
  text-decoration: none;
  text-align: start;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 2.2rem;
  text-align: left;
  display: flex;
  align-items: center;
  gap: 1.2rem;
  opacity: 1;
  transition: all 0.2s linear;
}

.footer__link:hover {
  opacity: 0.8;
}

.footer__text {
  font-family: "Montserrat", Arial, sans-serif;
  text-wrap: wrap;
  margin: 0;
  color: #fff;
  text-align: start;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 2.2rem;
  text-align: left;
}

.footer__icon-image {
  width: 2.4rem;
  height: 2.4rem;
}

@media (min-width: 1441px) {
  .footer__container {
    padding: 60px;
    max-width: 1440px;
    gap: 42px;
  }

  .footer__column {
    gap: 30px;
  }

  .footer__image {
    width: 160px;
    height: 44px;
  }

  .footer__policy {
    font-size: 14px;
    line-height: 17px;
  }

  .footer__title {
    font-size: 24px;
    line-height: 29px;
  }

  .footer__list {
    gap: 20px;
  }

  .footer__link {
    font-size: 18px;
    line-height: 22px;
    gap: 12px;
  }

  .footer__text {
    font-size: 18px;
    line-height: 22px;
  }

  .footer__icon-image {
    width: 24px;
    height: 24px;
  }
}

@media (max-width: 1024px) {
  .footer__container {
    padding: 2.4rem;
    gap: 3rem;
    display: flex;
    flex-wrap: wrap;
  }

  .footer__column {
    min-width: 32.9rem;
    max-width: 34.5rem;
    gap: 1.5rem;
  }

  .footer__column_type_logo {
    order: 4;
    width: 100%;
    max-width: max-content;
  }

  .footer__wrap {
    display: none;
  }

  .footer__column_type_services {
    order: 1;
  }

  .footer__column_type_company {
    display: none;
  }

  .footer__column_type_contacts {
    order: 0;
  }

  .footer__column_type_socials {
    order: 3;
  }

  .footer__image {
    width: 160px;
    height: 44px;
  }

  .footer__policy {
    font-size: 1.4rem;
    line-height: 1.7rem;
  }

  .footer__title {
    font-size: 2rem;
    line-height: 2.4rem;
  }

  .footer__list {
    gap: 1rem;
    align-items: flex-start;
  }

  .footer__list_type_small {
    gap: 1.2rem;
    display: flex;
    flex-direction: row;
  }

  .footer__list_type_high {
    display: none;
  }

  .footer__element {
    width: fit-content;
  }

  .footer__link {
    width: fit-content;
    font-size: 1.6rem;
    line-height: 1.95rem;
  }

  .footer__text {
    font-size: 1.6rem;
    line-height: 1.95rem;
  }

  .footer__icon-image {
    width: 4.8rem;
    height: 4.8rem;
  }
}

@media (max-width: 576px) {
  .footer__container {
    padding: 1.2rem;
    gap: 3rem;
    display: flex;
    flex-direction: column;
  }

  .footer__column {
    width: 100%;
    min-width: none;
    max-width: none;
    gap: 1.5rem;
  }

  .footer__column_type_logo {
    order: 4;
    width: 100%;
    max-width: max-content;
  }

  .footer__column_type_services {
    order: 3;
  }

  .footer__column_type_company {
    display: none;
  }

  .footer__column_type_contacts {
    order: 0;
  }

  .footer__column_type_socials {
    order: 2;
  }
  .footer__column_type_gallery {
    order: 3;
  }

  .footer__image {
    width: 160px;
    height: 44px;
  }

  .footer__policy {
    font-size: 1.4rem;
    line-height: 1.7rem;
  }

  .footer__title {
    font-size: 2rem;
    line-height: 2.4rem;
  }

  .footer__list {
    gap: 1rem;
    align-items: flex-start;
  }

  .footer__list_type_small {
    gap: 1.2rem;
    display: flex;
    flex-direction: row;
  }

  .footer__list_type_high {
    display: none;
  }

  .footer__element {
    width: fit-content;
  }

  .footer__link {
    width: fit-content;
    font-size: 1.6rem;
    line-height: 1.95rem;
  }

  .footer__text {
    font-size: 1.6rem;
    line-height: 1.95rem;
  }

  .footer__icon-image {
    width: 4.8rem;
    height: 4.8rem;
  }
}
