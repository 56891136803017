.patterns {
  margin: 4.8rem 0;
  width: 109.6rem;
  display: flex;
  flex-direction: column;
  gap: 4.6rem;
}

.patterns__list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 9.6rem;
}

.pattern {
  width: 100%;
  padding: 2.4rem;
  border-radius: 3.6rem;
  background: rgba(45, 45, 45, 0.4);
  display: flex;
  gap: 2.4rem;
  min-height: 33rem;
  box-sizing: border-box;
  align-items: center;
}

.pattern__image-container {
  position: relative;
  min-width: 41.1rem;
  height: 28.2rem;
  display: flex;
  border-radius: 1.2rem;
  overflow: hidden;
  cursor: pointer;
}

.pattern__image {
  position: relative;
  width: 100%;
  height: 28.2rem;
  object-fit: cover;
}

.pattern__zoom {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 4.8rem;
  height: 4.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(45, 45, 45, 0.4);
  backdrop-filter: blur(15px);
  border-top-left-radius: 1.2rem;
  border-bottom-right-radius: 1.2rem;
}

.pattern__container {
  display: flex;
  flex-direction: column;
  gap: 4.8rem;
}

.pattern__info {
  display: flex;
  gap: 1rem;
}

.pattern__title {
  margin: 0;
  color: #fff;
  font-size: 3.2rem;
  font-weight: 500;
  line-height: 4.2rem;
  text-align: left;
}

.pattern__title:first-child {
  padding: 0 1rem;
}

.pattern__text {
  padding-left: 4.8rem;
  margin: 0;
  font-size: 2.4rem;
  font-weight: 400;
  line-height: 2.9rem;
  text-align: left;
  color: rgba(152, 152, 152, 1);
}

@media (max-width: 1024px) {
  .patterns {
    margin-top: 0;
    padding: 2.4rem;
    width: 100%;
    box-sizing: border-box;
    gap: 2.4rem;
  }

  .patterns__list {
    width: 100%;
    gap: 4.8rem;
  }

  .pattern {
    min-height: 22.6rem;
  }

  .pattern__image-container {
    min-width: 25rem;
    height: 17.2rem;
  }

  .pattern__image {
    height: 17.2rem;
  }

  .pattern__container {
    gap: 2.4rem;
  }

  .pattern__title {
    font-size: 2.4rem;
    line-height: 2.9rem;
  }

  .pattern__title:first-child {
    padding: 0 0.4rem;
  }

  .pattern__text {
    padding-left: 3.6rem;
    font-size: 2rem;
    line-height: 2.4rem;
  }
}

@media (max-width: 576px) {
  .patterns {
    padding-bottom: 0;
  }
  .pattern {
    padding: 1.8rem;
    flex-direction: column;
    border-radius: 1.8rem;
  }

  .patterns__list {
    gap: 2.4rem;
  }

  .pattern__image-container {
    height: 18.4rem;
  }

  .pattern__image {
    width: 27.6rem;
    height: 18.4rem;
  }

  .pattern__info {
    gap: 0.5rem;
  }

  .pattern__title {
    font-size: 1.8rem;
    line-height: 2.2rem;
  }

  .pattern__text {
    padding-left: 2.7rem;
    font-size: 1.6rem;
    line-height: 1.95rem;
  }
}
