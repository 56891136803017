.advantages {
  position: relative;
  width: 132rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.advatages__text {
  margin: 0;
  width: 109.6rem;
  margin: 2.4rem 0 4.8rem;
  color: rgba(152, 152, 152, 1);
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 2.9rem;
  text-align: left;
  box-sizing: border-box;
}

.advantages__image {
  position: relative;
  margin-bottom: 5rem;
  top: 12.8rem;
  position: sticky;
  width: 109.6rem;
  height: 61.6rem;
  object-fit: cover;
  border-radius: 2.4rem;
  z-index: 1;
}

.advantages__image::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.advantages__list {
  width: 121.6rem;
  margin: 0;
  padding: 0;
  margin-top: -60.6rem;
  list-style: none;
  z-index: 2;
  display: flex;
  flex-direction: column;
}

.advantages__list {
  gap: 2.4rem;
}

.advantage {
  margin-top: -2.4rem;
  width: 59.6rem;
  padding: 2.4rem;
  box-sizing: border-box;
  display: flex;
  gap: 1.2rem;
  background-color: rgba(45, 45, 45, 0.4);
  border-radius: 2.4rem;
  backdrop-filter: blur(10px);
}

.advantage_type_cont {
  margin-top: 0;
  width: 82rem;
  gap: 2.4rem;
}

.advantage:nth-child(2n) {
  align-self: end;
}

.advantage__number {
  margin: 0;
  min-width: 6rem;
  height: 6rem;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(33, 33, 33, 1);
  font-size: 3.2rem;
  font-weight: 500;
  line-height: 4.2rem;
}

.advantage__container {
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
}

.advantage__title {
  margin: 0;
  color: #fff;
  font-size: 3.2rem;
  font-weight: 500;
  line-height: 4.2rem;
  text-align: left;
}

.advantage__title_type_cont {
  font-size: 4rem;
  line-height: 4.9rem;
  text-align: left;
  text-transform: uppercase;
}

.advantage__text {
  margin: 0;
  color: #fff;
  font-size: 2.4rem;
  font-weight: 400;
  line-height: 2.9rem;
  text-align: left;
}

.advantage__text_type_cont {
  font-size: 2rem;
  font-weight: 300;
  line-height: 2.4rem;
}

@media (max-width: 1024px) {
  .advantages {
    padding: 0 2.4rem;
    box-sizing: border-box;
    width: 100%;
    min-height: 187rem;
  }

  .advantages_type_cont {
    min-height: 104rem;
  }

  .supervision {
    min-height: 80rem;
  }

  .advatages__text {
    margin: 1.2rem 0 9.3rem;
    padding: 0 6rem;
    width: 72rem;
  }

  .advantages__image {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    width: calc(100% - 4.8rem);
    height: calc(100vh - 16rem);
  }

  .advantages__image::after {
    left: 2.4rem;
    width: calc(100% - 4.8rem);
    border-radius: 2.4rem;
  }

  .advantages__image_type_cont {
    height: 45.2rem;
  }

  .advantages__list {
    width: 100%;
    gap: 6rem;
    margin-top: calc(((100vh - 16rem) * -1) + 3rem);
  }

  .advatages__list_type_cont {
    margin-top: calc((45.2rem * -1) - 8rem);
  }

  .advantage {
    margin: 0;
    width: 62.4rem;
    align-self: flex-end;
  }

  .advantage_type_cont {
    width: 66rem;
    align-self: flex-start;
  }

  .advantage:nth-child(2n) {
    align-self: flex-start;
  }

  .advantage_type_cont:nth-child(2n) {
    align-self: flex-end;
  }

  .advantage__number {
    min-width: 5rem;
    height: 5rem;
    font-size: 2.4rem;
    line-height: 2.9rem;
  }

  .advantage__title {
    font-size: 2.4rem;
    line-height: 2.9rem;
  }

  .advantage__title_type_cont {
    font-size: 3.2rem;
  }

  .advantage__text {
    font-size: 1.6rem;
    line-height: 1.95rem;
  }

  .advantage__text_type_cont {
    font-weight: 300;
    font-size: 2rem;
    line-height: 2.4rem;
  }
}

@media (max-width: 576px) {
  .advantages {
    padding: 0 1.2rem;
    min-height: 176rem;
  }

  .advantages_type_cont {
    min-height: 91rem;
  }

  .supervision {
    min-height: 54rem;
  }

  .advantages_type_cont .section-title_type_team {
    padding: 0;
    text-align: start;
  }

  .advatages__text {
    margin: 1.2rem 0 5rem;
    padding: 0;
    width: 33.6rem;
    font-size: 1.6rem;
    line-height: 1.95rem;
  }

  .advantages__image {
    top: 9.6rem;
    width: 100%;
    height: calc(100vh - 12rem);
  }

  .advantages__image::after {
    left: 0;
    width: 100%;
  }

  .advantages__image_type_cont {
    height: 45.2rem;
  }

  .advantages__list {
    margin-top: calc((100vh - 9.6rem) * -1);
    align-items: center;
    gap: 2.4rem;
  }

  .advatages__list_type_cont {
    margin-top: calc(((45.2rem) * -1) - 8rem);
  }

  .advantage {
    width: calc(100% - 4.8rem);
    align-self: center;
  }

  .advantage_type_cont {
    width: calc(100% - 2.4rem);
    padding: 1.8rem;
    gap: 1.8rem;
  }

  .advantage:nth-child(2n) {
    align-self: center;
  }

  .advantage__number {
    min-width: 4rem;
    height: 4rem;
    font-size: 1.8rem;
    line-height: 2.2rem;
  }

  .advantage__title {
    font-size: 1.8rem;
    line-height: 2.2rem;
  }

  .advantage__title_type_cont {
    font-size: 2rem;
    line-height: 2.4rem;
  }

  .advantage__text {
    font-size: 1.4rem;
    line-height: 1.7rem;
  }

  .advantage__text_type_cont {
    font-size: 1.6rem;
    line-height: 1.95rem;
  }
}

@media (max-width: 450px) {
  .advantages {
    min-height: 218rem;
  }

  .advantages_type_cont {
    min-height: 108rem;
  }

  .supervision {
    min-height: 54rem;
  }
}
