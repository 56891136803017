.navigation {
  width: fit-content;
  align-items: center;
  display: flex;
  justify-content: space-between;
  transition: margin-top 0.2s linear, height 0.2s linear;
}

.navigation__list {
  z-index: 4;
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  gap: 3rem;
  transition: gap 0.2s linear;
}

.navigation__element {
  margin-top: 1.15rem;
  padding-bottom: 1rem;
  position: relative;
  text-decoration: none;
  display: flex;
  flex-direction: column;
}

.navigation__el {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  transition: all 0.2s linear;
}

.navigation__link {
  color: #fff;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.7rem;
  width: max-content;
  text-decoration: none;
  transition: all 0.2s linear;
}

.navigation__element::after {
  content: "";
  width: 0%;
  height: 0.15rem;
  background: #fff;
  align-self: flex-end;
  transition: all 0.1s linear;
}

.navigation__element::before {
  content: "";
  position: absolute;
  bottom: 1rem;
  width: 0%;
  height: 0.15rem;
  align-self: flex-end;
  background: rgba(230, 0, 126, 1);
  transition: all 0.3s linear;
}

.navigation__element:hover::after {
  align-self: flex-start;
  width: 100%;
}

.navigation__element:hover::before {
  align-self: flex-start;
  width: 100%;
}

/*  */

.navigation__dropdown {
  position: absolute;
  left: -1.2rem;
  top: 2.8rem;
  width: 19.6rem;
  background-color: rgba(45, 45, 45, 0.6);
  padding: 0.6rem;
  border-radius: 1.2rem;
  z-index: 5;
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  opacity: 0;
  visibility: hidden;
  backdrop-filter: blur(10px);
  transition: opacity 0.2s linear, visibility 0s 0.2s;
}

.navigation__dropdown_active {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.05s linear;
}

.navigation__dropdown .navigation__link {
  width: 100%;
  padding: 1.2rem;
  box-sizing: border-box;
  text-wrap: wrap;
  color: #fff;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.7rem;
  text-align: left;
  border-radius: 0.6rem;
  transition: all 0.05s linear;
}

.navigation__dropdown .navigation__link:hover {
  background: rgba(255, 255, 255, 0.1);
}

.navigation__sublinks {
  display: none;
}

@media (min-width: 1441px) {
  .navigation__list {
    gap: 30px;
  }

  .navigation__el {
    padding: 2px 0;
  }

  .navigation__link {
    font-size: 14px;
    line-height: 17px;
  }

  .navigation__element::after {
    height: 1px;
  }

  .navigation__element::before {
    height: 1px;
  }
}

@media (max-width: 1024px) {
  .navigation {
    width: 100%;
  }

  .navigation__list {
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: 12px;
  }

  .navigation__element {
    padding: 0;
    margin: 12px 0;
    width: 100%;
    text-align: center;
  }

  .navigation__element:hover::after {
    align-self: flex-end;
    width: 0;
  }

  .navigation__element:hover::before {
    align-self: flex-end;
    width: 0;
  }

  .navigation__el {
    height: 17px;
    padding: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .navigation__link {
    font-size: 14px;
    line-height: 17px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
  }

  .navigation__dropdown {
    display: none;
  }

  .navigation__hover-area {
    height: fit-content;
    position: relative;
  }

  .navigation__sublinks {
    margin-top: 0;
    gap: 1.2rem;
    display: flex;
    flex-direction: column;
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: max-height 0.4s ease, opacity 0.4s ease, margin-top 0.4s ease;
  }

  .navigation__sublink {
    margin: 0;
    color: rgba(152, 152, 152, 1);
    text-decoration: none;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.7rem;
    text-align: start;
  }

  .navigation__sublinks_active {
    margin-top: 1.2rem;
    max-height: 400px; /* Укажите максимальную высоту для полного отображения */
    opacity: 1;
  }

  /* Поворот SVG-стрелки */
  .navigation__el svg {
    transition: transform 0.4s ease;
    transform: rotate(180deg); /* Изначально повернут */
  }

  /* Активная стрелка для открытого подменю */
  .rotate-svg {
    transform: rotate(0deg) !important; /* Поворачиваем в исходное положение */
  }
}
