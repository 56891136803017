.object {
  max-width: 144rem;
  margin-top: 8rem;
  padding: 2.5rem 6rem 4.6rem;
  min-height: 100dvh;
  box-sizing: border-box;
}

.object__header {
  margin-bottom: 2.4rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.object__button {
  padding: 0;
  display: flex;
  align-items: center;
  gap: 0.6rem;
  outline: none;
  background-color: transparent;
  border: 0;
  color: rgba(152, 152, 152, 1);
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 2.2rem;
  text-align: justified;
  cursor: pointer;
}

.object__button-image {
  width: 2.4rem;
  height: 2.4rem;
}

.object__discuss {
  align-self: flex-end;
  margin-right: 10rem;
}

.object__prices {
  margin: 2rem auto 0;
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: start;
}

.object__price {
  padding: 2rem 0;
  margin: 0;
  color: #fff;
  font-size: 2.8rem;
  line-height: 3.4rem;
  font-weight: 500;
}

.object__navigations {
  align-self: center;
  padding: 2rem 4rem;
  display: flex;
  width: fit-content;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  background-color: rgba(45, 45, 45, 0.4);
  border-radius: 2.4rem;
  box-sizing: border-box;
}

.object__navigation {
  color: #fff;
  font-size: 2rem;
  cursor: pointer;
}

.object__manuscript {
  border-radius: 1.2rem;
  width: 40.6rem;
  height: 5.4rem;
}

.object__content {
  display: flex;
  flex-direction: column;
  gap: 8rem;
}

.object__container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.object__container_type_gallery {
  align-self: center;
  width: 109.6rem;
}

.object__title {
  margin: 0 0 4.6rem;
  width: 100%;
  color: #fff;
  font-size: 4.5rem;
  font-weight: 500;
  line-height: 5.5rem;
  text-align: left;
}

.object__title_type_video {
  text-align: center;
}

.object__columns {
  width: 100%;
  display: flex;
  gap: 4.8rem;
}

.object__image {
  min-width: 75.6rem;
  height: 52.2rem;
  object-fit: cover;
  object-position: center;
  border-radius: 2.4rem;
}

.object__column {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
}

.object__text {
  margin: 0;
  color: rgba(118, 118, 118, 1);
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.8rem;
  text-align: left;
}

.object__params {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

.object__param {
  color: rgba(118, 118, 118, 1);
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 2.2rem;
  display: flex;
  gap: 3rem;
  justify-content: space-between;
  padding: 1.2rem 0;
  border-bottom: 0.1rem solid rgba(71, 71, 71, 1);
}

.object__value {
  color: #fff;
  text-align: end;
}

.object__slider {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 76vh;
  box-sizing: border-box;
  padding: 4rem 8rem;
  background-color: rgba(45, 45, 45, 0.4);
  border-radius: 2.4rem;
}

.object__slider img-comparison-slider {
  position: relative;
  overflow: visible;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  --divider-color: rgba(36, 36, 36, 1);
  --divider-width: 0.8rem;
  --default-handle-width: 3rem;
  --default-handle-color: #fff;
}

.object__stick {
  background-color: rgba(36, 36, 36, 1);
  border-radius: 50%;
}

.object__arrows {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4rem;
  height: 4rem;
}

.object__arrow {
  width: 2.4rem;
  height: 2.4rem;
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7475%)
    hue-rotate(66deg) brightness(108%) contrast(98%);
}

.object__arrow:nth-of-type(2) {
  margin-left: -0.6rem;
  transform: rotate(180deg);
}

.object__slider img-comparison-slider:focus {
  outline: none;
}

.object__layout {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.l {
  height: 66vh;
  position: relative;
}

.object__gallery {
  width: 100%;
  display: flex;
  gap: 4.8rem;
}

.object__gallery_type_column {
  flex-direction: column;
}

.object__gallery-column {
  width: calc(50% - 2.4rem);
  display: flex;
  flex-direction: column;
  gap: 4.8rem;
}

.object__gallery-image {
  width: 100%;
  height: fit-content;
  object-fit: contain;
  object-position: center center;
  cursor: pointer;
  border-radius: 2.4rem;
  grid-column: span 1;
  transition: all 0.2s linear;
}

.object__gallery-image:hover {
  transform: scale(1.02);
}

@media (min-width: 1441px) {
  .object {
    max-width: 1440px;
    margin-top: 80px;
    padding: 25px 60px 46px;
  }

  .object__header {
    margin-bottom: 24px;
  }

  .object__button {
    gap: 6px;
    font-size: 18px;
    line-height: 22px;
  }

  .object__button-image {
    width: 24px;
    height: 24px;
  }

  .object__manuscript {
    border-radius: 12px;
    width: 406px;
    height: 54px;
  }

  .object__content {
    gap: 80px;
  }

  .object__container_type_gallery {
    width: 1096px;
  }

  .object__title {
    margin: 0 0 46px;
    font-size: 45px;
    line-height: 55px;
  }

  .object__columns {
    gap: 48px;
  }

  .object__image {
    min-width: 756px;
    height: 522px;
    border-radius: 24px;
  }

  .object__column {
    gap: 24px;
  }

  .object__text {
    font-size: 14px;
    line-height: 18px;
  }

  .object__params {
    gap: 12px;
  }

  .object__param {
    font-size: 18px;
    line-height: 22px;
    gap: 30px;
    padding: 12px 0;
    border-bottom: 1px solid rgba(71, 71, 71, 1);
  }

  .object__slider {
    padding: 40px 80px;
    border-radius: 24px;
  }

  .object__slider img-comparison-slider {
    --divider-width: 8px;
    --default-handle-width: 30px;
  }

  .object__arrows {
    width: 40px;
    height: 40px;
  }

  .object__arrow {
    width: 24px;
    height: 24px;
  }

  .object__arrow:nth-of-type(2) {
    margin-left: -6px;
  }

  .object__gallery {
    gap: 48px;
  }

  .object__gallery-column {
    width: calc(50% - 24px);
    gap: 48px;
  }

  .object__gallery-image {
    border-radius: 2.4rem;
  }
}

@media (max-width: 1024px) {
  .object {
    margin-top: 7.2rem;
    max-width: none;
    width: 100%;
    padding: 2.5rem 2.4rem 4.6rem;
  }

  .object__button {
    font-size: 1.6rem;
    line-height: 1.95rem;
  }

  .object__discuss {
    align-self: center;
    margin-right: 0;
  }

  .object__price {
    font-size: 2rem;
    line-height: 2.4rem;
  }

  .object__navigation {
    font-size: 1.6rem;
  }

  .object__title {
    font-size: 3.2rem;
    line-height: 3.9rem;
  }

  .object__content {
    gap: 4.8rem;
  }

  .object__columns {
    width: 100%;
    flex-direction: column;
    gap: 2.4rem;
  }

  .object__image {
    min-width: auto;
    width: 100%;
    height: 34.4rem;
  }

  .object__param {
    font-size: 1.6rem;
    line-height: 1.95rem;
  }

  .object__title_type_video {
    margin-bottom: 2.4rem;
  }

  .object__container_type_gallery {
    width: 100%;
  }

  .object__gallery {
    gap: 2.4rem;
  }

  .object__gallery-column {
    width: calc(50% - 1rem);
    gap: 2.4rem;
  }
}

@media (max-width: 576px) {
  .object {
    padding: 2.5rem 1.2rem 4.6rem;
  }

  .object__button {
    font-size: 1.4rem;
    line-height: 1.7rem;
  }

  .object__prices {
    margin: 1rem;
    box-sizing: border-box;
  }

  .object__navigations {
    padding: 2rem 3rem;
    flex-direction: column;
  }

  .object__navigation {
    font-size: 1.4rem;
  }

  .object__price {
    font-size: 1.6rem;
    line-height: 1.95rem;
  }

  .object__manuscript {
    width: 20.3rem;
    height: 2.7rem;
  }

  .object__title {
    margin-bottom: 2.4rem;
    font-size: 2.4rem;
    line-height: 2.9rem;
  }

  .object__image {
    height: auto;
    border-radius: 1.8rem;
  }

  .object__text {
    font-size: 1.2rem;
    line-height: 1.46rem;
  }

  .object__param {
    font-size: 1.4rem;
    line-height: 1.7rem;
  }

  .object__slider {
    padding: 2.4rem 3rem;
    height: auto;
  }

  .l {
    height: auto;
  }

  .object__gallery-image {
    height: auto;
  }
}
