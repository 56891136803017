.logo {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.logo__image {
  width: 16rem;
  height: 4.4rem;
}

@media (min-width: 1441px) {
  .logo__image {
    width: 160px;
    height: 44px;
  }
}

@media (max-width: 1024px) {
  .logo__image {
    width: 12rem;
    height: 4.2rem;
  }
}