.contacts {
  margin-top: 8.8rem;
  width: 100%;
  padding: 4.7rem 17.2rem 6rem;
  box-sizing: border-box;
  min-height: calc(100vh - 47.9rem);
  display: flex;
  flex-direction: column;
  gap: 4.8rem;
}

.contacts__title {
  width: 109.6rem;
  align-self: center;
  margin: 0;
  color: #fff;
  font-size: 4.5rem;
  font-weight: 500;
  line-height: 5.9rem;
  text-align: center;
}

.contacts__container {
  width: fit-content;
  position: relative;
  align-self: center;
}

.contacts__card {
  backdrop-filter: blur(10px);
  padding: 2.4rem;
  box-sizing: border-box;
  background-color: rgba(45, 45, 45, 0.6);
  position: absolute;
  left: 2.4rem;
  width: 40rem;
  height: 29.3rem;
  top: calc(50% - 14.65rem);
  border-radius: 2.4rem;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
}

.contacts__subtitle {
  margin: 0;
  color: #fff;
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 2.9rem;
  text-align: center;
}

.contacts__link {
  margin: 0;
  text-decoration: none;
  color: #fff;
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 2.2rem;
  text-align: center;
}

.contacts__links {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

.contacts__map {
  width: 109.6rem;
  height: 50.8rem;
  border-radius: 4.8rem;
}

.contacts__text {
  margin: 0;
  color: #fff;
  width: 100%;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 2.2rem;
  text-align: center;
}

@media (min-width: 1441px) {
  .contacts {
    margin-top: 88px;
    padding: 47px 172px 60px;
    min-height: calc(100vh - 479px);
    gap: 48px;
    width: 1096px;
    box-sizing: content-box;
  }

  .contacts__title {
    font-size: 45px;
    line-height: 59px;
  }

  .contacts__card {
    padding: 24px;
    left: 24px;
    width: 400px;
    height: 293px;
    top: calc(50% - 146.5px);
    border-radius: 24px;
    gap: 24px;
  }

  .contacts__subtitle {
    font-size: 24px;
    line-height: 29px;
  }

  .contacts__link {
    font-size: 18px;
    line-height: 22px;
  }

  .contacts__links {
    gap: 12px;
  }

  .contacts__map {
    width: 1096px;
    height: 508px;
    border-radius: 48px;
  }

  .contacts__text {
    font-size: 18px;
    line-height: 22px;
  }
}

@media (max-width: 1024px) {
  .contacts {
    margin-top: 7.2rem;
    padding: 2.4rem 2.4rem 4.6rem;
    gap: 2.4rem;
  }

  .contacts__title {
    width: 100%;
    font-size: 3.2rem;
    line-height: 3.9rem;
  }

  .contacts__card {
    width: 32.4rem;
  }

  .contacts__link,
  .contacts__text {
    font-size: 1.6rem;
    line-height: 1.95rem;
  }

  .contacts__map {
    width: calc(100vw - 4.8rem);
    height: 32.9rem;
  }
}

@media (max-width: 576px) {
  .contacts {
    padding: 4.8rem 1.2rem;
    gap: 4.8rem;
  }

  .contacts__subtitle {
    display: none;
  }

  .contacts__title {
    text-align: center;
    font-size: 2.4rem;
    line-height: 2.9rem;
  }

  .contacts__link {
    font-size: 1.8rem;
    line-height: 2.2rem;
  }

  .contacts__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
  }

  .contacts__card {
    padding: 3.6rem;
    width: 100%;
    position: static;
    height: fit-content;
  }

  .contacts__map {
    width: 100%;
    border-radius: 2.4rem;
  }

  .contacts__text {
    font-size: 1.4rem;
    line-height: 1.7rem;
  }
}
