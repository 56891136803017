@keyframes close {
  0% {
    opacity: 1;
    display: flex;
  }
  100% {
    opacity: 0;
    display: none;
  }
}

@keyframes open {
  0% {
    visibility: hidden;
    opacity: 0;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}

.quiz {
  width: 100%;
  max-width: 144rem;
}

.quiz__bin {
  width: 100%;
  padding: 0 6rem 15rem;
  box-sizing: border-box;
  display: flex;
}

.quiz__questions {
  margin-right: 6rem;
  position: relative;
  display: flex;
  min-width: 72.4rem;
  flex-direction: column;
  gap: 2rem;
  overflow: hidden;
}

.quiz__questions_disactive {
  animation: close 1s forwards;
}

.quiz__container {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.quiz__number {
  margin: 0.2rem;
  position: relative;
  color: rgba(152, 152, 152, 1);
  width: 8rem;
  height: 8rem;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.1rem solid rgba(131, 131, 131, 1);
  border-radius: 50%;
  font-size: 3.2rem;
  font-weight: 400;
  line-height: 3.9rem;
  z-index: 1;
}

.quiz__number::before {
  margin: 0.2rem;
  content: "";
  position: absolute;
  align-self: center;
  justify-self: center;
  width: 97%;
  height: 96%;
  border-radius: 50%;
  border: 0.3rem solid rgba(230, 0, 126, 1);
  transition: all 1s ease;
  z-index: -1;
}

.quiz__number[data-progress="1"]::before {
  clip-path: polygon(0% 50%, 0% 0%, 50% 0%, 50% 12.81%, 50% 25%, 50% 50%);
}

.quiz__number[data-progress="2"]::before {
  clip-path: polygon(0% 100%, 0% 0%, 50% 0%, 50% 28.2%, 50% 50%, 50% 100%);
}

.quiz__number[data-progress="3"]::before {
  clip-path: polygon(0% 100%, 0% 0%, 50% 0%, 50% 50%, 100% 50%, 100% 100%);
}

.quiz__number[data-progress="4"]::before {
  clip-path: polygon(0% 100%, 0% 0%, 50% 0%, 50% 0%, 100% 0%, 100% 100%);
}

.quiz__number_active {
  font-weight: 500;
  color: #fff;
}

.quiz__title {
  margin: 0;
  width: 85%;
  color: #fff;
  font-size: 3.2rem;
  font-weight: 500;
  line-height: 3.9rem;
}

.quiz__columns {
  display: flex;
  width: 100%;
  overflow-x: scroll;
  gap: 4rem;
  scroll-snap-type: x mandatory;
  overflow-y: hidden;
  transition: height 0.2s linear;
}

.quiz__columns::-webkit-scrollbar {
  display: none;
}

.quiz__column {
  min-width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  scroll-snap-align: start;
  height: fit-content;
}

.quiz__buttons {
  display: flex;
  justify-content: space-between;
}

.quiz__button {
  margin: 0;
  transform: translateX(-200px);
  transition: all 0.4s ease-out;
}

.quiz__button_type_next {
  margin: 0;
  transform: translateX(200px);
  transition: all 0.4s ease-out;
}

.quiz__button_active {
  transform: translateX(0);
}

/*  */
.quiz__response {
  margin-right: 0;
  opacity: 0;
  visibility: hidden;
  display: flex;
  width: 0;
  max-height: 0;
  overflow: hidden;
  flex-direction: column;
  gap: 2.4rem;
  transition: width 0s linear 1s, margin-right 0s linear 1s,
    max-height 0s linear 1s;
}

.quiz__response_active {
  max-height: 50rem;
  margin-right: 2.4rem;
  width: calc(50% - 1.2rem);
  animation: open 1s forwards 1s;
}

.quiz__response-title {
  margin: 4.8rem 0 0;
  color: #fff;
  font-size: 4.5rem;
  font-weight: 500;
  line-height: 5.5rem;
  text-align: left;
}

.quiz__text {
  margin: 0;
  color: #fff;
  font-size: 2.4rem;
  font-weight: 400;
  line-height: 2.9rem;
  text-align: left;
}

.quiz__form {
  opacity: 0;
  visibility: hidden;
  width: 0;
  max-height: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: width 0s linear 1s, max-height 0s linear 1s;
}

.quiz__form_active {
  max-height: 50rem;
  width: calc(50% - 1.2rem);
  animation: open 1s forwards 1s;
}

.summary {
  padding: 2.4rem;
  box-sizing: border-box;
  height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  background-color: rgba(45, 45, 45, 0.4);
  border-radius: 2.4rem;
}

.summary_disactive {
  animation: close 1s forwards;
}

.summary__person {
  display: flex;
  align-items: center;
  gap: 2.4rem;
}

.summary__image {
  min-width: 8rem;
  min-height: 8rem;
  max-width: 8rem;
  max-height: 8rem;
  object-fit: cover;
  background-color: rgba(154, 154, 154, 1);
  border-radius: 50%;
  object-position: top;
}

.summary__info {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
}

.summary__title {
  margin: 0;
  width: 100%;
  color: #fff;
  font-size: 2.4rem;
  font-weight: 400;
  line-height: 2.9rem;
  text-align: left;
}

.summary__subtitle {
  margin: 0;
  width: 100%;
  color: rgba(152, 152, 152, 1);
  font-size: 2rem;
  font-weight: 500;
  line-height: 2.4rem;
  text-align: left;
}

.summary__text {
  margin: 0;
  padding: 2.4rem;
  box-sizing: border-box;
  width: 100%;
  background-color: rgba(45, 45, 45, 0.4);
  border-radius: 2.4rem;
  color: #fff;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 2.2rem;
  text-align: left;
}

.summary__container {
  margin-top: 2.4rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.4rem;
}

.summary__paragraph {
  margin: 0;
  width: 100%;
  color: #fff;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 2.2rem;
  text-align: center;
}

.summary__button {
  width: 100%;
}

@media (min-width: 1441px) {
  .quiz {
    max-width: 1440px;
  }

  .quiz__bin {
    padding: 0 60px 150px;
  }

  .quiz__questions {
    margin-right: 60px;
    min-width: 724px;
    gap: 20px;
  }

  .quiz__container {
    gap: 15px;
  }

  .quiz__number {
    width: 80px;
    height: 80px;
    border: 1px solid rgba(131, 131, 131, 1);
    font-size: 32px;
    line-height: 39.01px;
  }

  .quiz__number {
    margin: 2px;
    min-width: 80px;
    height: 80px;
    border: 1px solid rgba(131, 131, 131, 1);
    font-size: 32px;
    line-height: 39.01px;
  }

  .quiz__number::before {
    width: 100%;
    height: 100%;
    border: 2px solid rgba(230, 0, 126, 1);
  }

  .quiz__title {
    font-size: 32px;
    line-height: 39.01px;
  }

  .quiz__columns {
    gap: 40px;
  }

  .quiz__column {
    gap: 20px;
  }

  .quiz__response {
    max-height: 500px;
    gap: 24px;
  }

  .quiz__response_active {
    margin-right: 24px;
    width: calc(50% - 12px);
  }

  .quiz__response-title {
    margin: 48px 0 0;
    font-size: 45px;
    line-height: 55px;
  }

  .quiz__text {
    font-size: 24px;
    line-height: 29px;
  }

  .quiz__form {
    max-height: 500px;
  }

  .quiz__form_active {
    width: calc(50% - 12px);
  }

  .summary {
    padding: 24px;
    gap: 24px;
    border-radius: 24px;
  }

  .summary__person {
    gap: 24px;
  }

  .summary__image {
    min-width: 80px;
    min-height: 80px;
    max-width: 80px;
    max-height: 80px;
  }

  .summary__info {
    gap: 6px;
  }

  .summary__title {
    font-size: 24px;
  }

  .summary__subtitle {
    font-size: 20px;
    line-height: 24px;
  }

  .summary__text {
    padding: 24px;
    border-radius: 24px;
    font-size: 18px;
    line-height: 22px;
  }

  .summary__container {
    margin-top: 24px;
    gap: 24px;
  }

  .summary__paragraph {
    font-size: 18px;
    line-height: 22px;
  }
}

@media (max-width: 1024px) {
  .quiz__bin {
    padding: 0 2.4rem 17rem;
    flex-direction: column;
  }

  .quiz__questions {
    margin-right: 0;
    margin-bottom: 2.4rem;
    min-width: auto;
    width: 100%;
  }

  .quiz__number {
    min-width: auto;
    width: 6rem;
    height: 6rem;
    font-size: 1.8rem;
    line-height: 2.2rem;
  }

  .quiz__title {
    font-size: 2.4rem;
    line-height: 2.9rem;
  }

  .quiz__response {
    margin-bottom: 0;
    width: 100%;
    transition: width 0s linear 1s, margin-bottom 0s linear 1s,
      max-height 0s linear 1s;
  }

  .quiz__response_active {
    margin-right: 0;
    margin-bottom: 2.4rem;
  }

  .quiz__response-title {
    font-size: 3.2rem;
    line-height: 3.9rem;
  }

  .quiz__text {
    margin-bottom: 4.8rem;
    font-size: 1.6rem;
    line-height: 1.95rem;
  }

  .quiz__form {
    width: 100%;
  }

  .summary__text {
    font-size: 1.6rem;
    line-height: 1.95rem;
  }

  .summary__paragraph {
    font-size: 1.6rem;
    line-height: 1.95rem;
  }
}

@media (max-width: 576px) {
  .quiz .section-title {
    text-align: start;
  }

  .quiz__bin {
    padding: 2.4rem 1.2rem;
  }

  .quiz__title {
    font-size: 1.8rem;
    line-height: 2.2rem;
    width: 75%;
  }

  .summary__title {
    font-size: 1.8rem;
    line-height: 2.2rem;
  }

  .summary__subtitle {
    font-size: 1.6rem;
    line-height: 1.95rem;
  }

  .summary__text {
    padding: 1.8rem;
  }

  .summary__paragraph {
    font-size: 1.4rem;
    line-height: 1.7rem;
  }

  .quiz__buttons .button_type_glasses {
    width: fit-content;
  }

  .quiz__response {
    gap: 1.2rem;
  }

  .quiz__response-title {
    margin: 0;
    font-size: 2.4rem;
    line-height: 2.9rem;
  }

  .quiz__text {
    margin: 0;
  }
}
