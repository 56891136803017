.scheme {
  width: 100%;
  max-width: 144rem;
  margin-bottom: 6rem;
}

.scheme__container {
  padding: 0 6rem;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 6rem;
  width: 100%;
  box-sizing: border-box;
}

.step {
  display: flex;
  gap: 2.4rem;
}

.step__info {
  height: 47.4rem;
  width: fit-content;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  transform: rotate(-180deg);
  position: sticky;
  top: 14.8rem;
  writing-mode: tb-rl;
}

.step__title {
  margin: 0;
  color: rgba(112, 112, 112, 1);
  font-size: 2.8rem;
  font-weight: 700;
  line-height: 3.4rem;
  text-align: left;
}

.step__subtitle {
  margin: 0;
  color: #fff;
  font-size: 4rem;
  font-weight: 600;
  line-height: 4.9rem;
  text-align: left;
}

.step__image {
  width: 76rem;
  height: 47.4rem;
  border-radius: 2.4rem;
  position: sticky;
  top: 14.8rem;
  object-fit: cover;
}

.points {
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  width: 42.2rem;
}

.point {
  padding: 2.4rem;
  background-color: rgba(45, 45, 45, 0.4);
  border-radius: 2.4rem;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

.point__title {
  margin: 0;
  color: rgba(112, 112, 112, 1);
  font-size: 3.2rem;
  font-weight: 400;
  line-height: 3.9rem;
  text-align: left;
}

.point__text {
  margin: 0;
  color: #fff;
  font-size: 2.4rem;
  font-weight: 400;
  line-height: 2.9rem;
  text-align: left;
}

.point__list {
  padding-left: 2rem;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

.point__item {
  color: #fff;
  font-size: 2.4rem;
  font-weight: 400;
  line-height: 2.9rem;
  text-align: left;
}

@media (min-width: 1441px) {
  .scheme {
    margin-bottom: 60px;
    width: 1440px;
  }
  
  .scheme__container {
    padding: 0 60px;
    gap: 60px;
  }
  
  .step {
    gap: 24px;
  }
  
  .step__info {
    height: 474px;
    gap: 10px;
    top: 148px;
  }
  
  .step__title {
    font-size: 28px;
    line-height: 33.89px;
  }
  
  .step__subtitle {
    font-size: 40px;
    line-height: 48.76px;
  }
  
  .step__image {
    width: 760px;
    height: 474px;
    border-radius: 24px;
    top: 148px;
  }
  
  .points {
    gap: 24px;
    width: 422px;
  }
  
  .point {
    padding: 24px;
    border-radius: 24px;
    gap: 12px;
  }
  
  .point__title {
    font-size: 32px;
    line-height: 38.73px;
  }
  
  .point__text {
    font-size: 24px;
    line-height: 29.26px;
  }
  
  .point__list {
    padding-left: 20px;
    gap: 12px;
  }
  
  .point__item {
    font-size: 24px;
    line-height: 29.26px;
  }
}

@media (max-width: 1024px) {
  .scheme {
    margin-bottom: 4.8rem;
  }

  .scheme__container {
    padding: 0 2.4rem;
    gap: 4.8rem;
  }

  .step {
    flex-wrap: wrap;
    gap: 2.4rem;
  }

  .step__image {
    top: -62rem;
    order: -1;
    width: 100%;
    height: 47.4rem;
  }

  .step__info {
    width: 7.3rem;
  }

  .step__title {
    font-size: 2rem;
    line-height: 2.4rem;
  }

  .step__subtitle {
    font-size: 3.2rem;
    line-height: 3.9rem;
  }

  .points {
    width: calc(100% - 9.7rem);
  }

  .point__title {
    font-size: 2.4rem;
    line-height: 2.9rem;
  }

  .point__text {
    font-size: 1.6rem;
    line-height: 1.95rem;
  }

  .point__item {
    font-size: 1.6rem;
    line-height: 1.95rem;
  }
}

@media (max-width: 576px) {
  .scheme__container {
    padding: 0 1.2rem;
  }
  .step {
    gap: 1.2rem;
  }

  .step__image {
    top: -22rem;
    margin-bottom: 1.2rem;
    order: -1;
    width: 100%;
    height: 21rem;
  }

  .step__info {
    margin-top: 10rem;
    bottom: 2rem;
    align-self: flex-end;
    height: 37.2rem;
    width: 6.1rem;
  }
  
  .step__title {
    font-size: 2rem;
    line-height: 2.4rem;
  }
  .step__subtitle {
    font-size: 2.4rem;
    line-height: 2.9rem;
  }

  .points {
    width: calc(100% - 7.3rem);
    gap: 1.2rem;
  }
}