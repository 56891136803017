@keyframes openError {
  from {
      opacity: 0;
   }
   to {
      opacity: 1;
   }
 }

.inputs-container {
  height: 6.6rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  max-width: 100%;
  display: flex;
  flex-direction: column;
}

.input-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 4.7rem;
  box-sizing: border-box;
  width: 100%;
  background-color: transparent;
  border-radius: 0.6rem;
  overflow: hidden;
}

.input {
  padding: 1.5rem 1.6rem;
  border: 0;
  width: 100%;
  height: 100%;
  font-size: 1.4rem;
  color: rgba(255, 255, 255, 1);
  box-sizing: border-box;
  background-color: rgba(74, 74, 74, 1);
  transition: all 0.2s linear;
  outline: none;
}

.input_type_path {
  padding-left: 0.1rem;
}

.input-container:focus-within {
  border: 0.1rem solid #fff;
}

.inputs-container:focus-within .input-error_active {
  display: none;
}

.input-container_type_error {
  border: 0.1rem solid #da1414;
}

.input__title {
  background-color: white;
  padding: 0.5rem 1rem;
  margin: 0;
  position: absolute;
  border-radius: 0.4rem;
  top: -0.9rem;
  left: 1.6rem;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 72%;
  color: #9d9aa1;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s linear;
}

.input__title_type_error {
  color: #da1414;
}

.input__title_active {
  opacity: 1;
  visibility: visible;
}

.input-error {
  display: none;
  overflow: hidden;
  align-items: start;
  gap: 0.5rem;
  min-height: 1.5rem;
  padding-left: 1.6rem;
  font-weight: 400;
  font-size: 1.2rem;
  color: #da1414;
  transition: all .2s linear;
}

.input-error_active {
  display: flex;
  animation: openError 0.3s;
}

.input-path {
  padding: 1.5rem 0 1.5rem 1.6rem;
  margin: 0;
  height: 4.7rem;
  box-sizing: border-box;
  font-weight: 500;
  font-size: 1.4rem;
  color: rgb(110, 110, 110);
  cursor: pointer;
  background-color: rgba(74, 74, 74, 1);
  transition: all .2s linear;
}

.input-path_active {
  color: #fff;
}

@media (min-width: 1441px) {
  .inputs-container {
    height: 66px;
  }
  
  .input-container {
    height: 47px;
    border-radius: 6px;
  }
  
  .input {
    padding: 15px 16px;
    font-size: 14px;
  }
  
  .input_type_path {
    padding-left: 1px;
  }
  
  .input-container:focus-within {
    border: 1px solid #fff;
  }
  
  .input-container_type_error {
    border: 1px solid #da1414;
  }
  
  .input__title {
    padding: 5px 10px;
    border-radius: 4px;
    top: -9px;
    left: 16px;
    font-size: 12px;
  }
  
  .input-error {
    gap: 5px;
    min-height: 15px;
    padding-left: 16px;
    font-size: 12px;
  }

  .input-path {
    padding: 15px 0 15px 16px;
    height: 47px;
    font-size: 14px;
  }
}