.not-found {
  margin-top: 8.8rem;
  width: 100%;
  padding: 4.7rem 0 6rem;
  box-sizing: border-box;
  min-height: calc(100vh - 47.9rem);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.not-found__container {
  padding: 4.8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.not-found__image {
  width: 28.7rem;
  height: 12.1rem;
  object-fit: contain;
}

.not-found__title {
  width: 38.1rem;
  margin: 3.6rem 0 6rem;
  color: #fff;
  font-size: 2rem;
  font-weight: 400;
  line-height: 2.4rem;
  text-align: center;
}

.not-found__link {
  color: rgba(140, 0, 68, 1);
  text-decoration: none;
  cursor: pointer;
}

@media (max-width: 1024px) {
  .not-found__title {
    width: 34.3rem;
    font-size: 1.8rem;
    line-height: 2.2rem;
  }
}

@media (max-width: 576px) {
  .not-found__container {
    width: 100%;
    padding: 1.2rem;
    box-sizing: border-box;
  }

  .not-found__image {
    width: 24rem;
    height: 10.1rem;
  }

  .not-found__title {
    width: 100%;
    font-size: 1.6rem;
    line-height: 1.95rem;
  }
}
