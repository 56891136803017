.prices {
  padding: 4.7rem 0;
  margin-top: 8.8rem;
  box-sizing: border-box;
  min-height: calc(100vh - 47.9rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4.8rem;
}

.prices__container {
  width: 109.6rem;
  display: flex;
  flex-direction: column;
}

.rs-panel-title {
  background-color: transparent;
}

.prices__element {
  padding: 2.4rem 0;
}

.prices__question {
  font-size: 2.8rem;
  line-height: 3.4rem;
}

.prices__wrap {
  display: none;
}

@media (max-width: 1024px) {
  .prices {
    width: 100%;
    margin-top: 7.2rem;
    padding: 2.4rem 2.4rem 4.6rem;
    gap: 2.4rem;
  }

  .prices__container {
    width: 100%;
  }

  .prices__question {
    font-size: 2rem;
    line-height: 2.4rem;
  }
}

@media (max-width: 576px) {
  .prices .contacts__title {
    text-align: center;
  }

  .prices__element {
    padding: 1.8rem 0;
  }

  .prices__question {
    font-size: 1.6rem;
    line-height: 1.95rem;
  }

  .prices__wrap {
    display: flex;
  }
}
