@keyframes popupOpen {
  0% {
    z-index: -2;
    opacity: 0;
    visibility: hidden;
  }

  25% {
    z-index: 3;
  }

  100% {
    z-index: 3;
    opacity: 1;
    visibility: visible;
  }
}

@keyframes popupClose {
  0% {
    z-index: 3;
    opacity: 1;
    visibility: visible;
  }

  75% {
    z-index: 3;
  }

  100% {
    z-index: -2;
    opacity: 0;
    visibility: hidden;
  }
}

.popup {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  z-index: -2;
  visibility: hidden;
}

.popup_active {
  animation: popupOpen 0.5s forwards;
}

.popup_closed {
  animation: popupClose 0.8s forwards;
}

.popup__container {
  padding: 2.4rem;
  position: relative;
  box-sizing: border-box;
  width: 42.4rem;
  height: fit-content;
  background-color: rgba(45, 45, 45, 1);
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
  border-radius: 2.4rem;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  overflow: hidden;
  align-items: center;
  transition: all 0.2s linear;
}

.popup__close-button {
  width: 2.4rem;
  height: 2.4rem;
  border: 0;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  position: absolute;
  top: 2.4rem;
  right: 2.4rem;
}

.popup__title {
  width: 32.8rem;
  margin: 0;
  color: #fff;
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 2.9rem;
  text-align: center;
}

.popup__text {
  width: 100%;
  margin: 0;
  color: #fff;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 2.2rem;
  text-align: center;
}

.popup__form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
}

.popup__container .button {
  height: 5.2rem;
}

@media (min-width: 1441px) {
  .popup__container {
    padding: 24px;
    width: 424px;
    border-radius: 24px;
    gap: 24px;
  }

  .popup__close-button {
    width: 24px;
    height: 24px;
    top: 24px;
    right: 24px;
  }

  .popup__title {
    width: 328px;
    font-size: 24px;
    line-height: 29px;
  }

  .popup__text {
    font-size: 18px;
    line-height: 22px;
  }

  .popup__form {
    gap: 6px;
  }
}

@media (max-width: 1024px) {
  .popup__container {
    width: 47.2rem;
  }

  .popup__title {
    font-size: 2rem;
    line-height: 2.4rem;
  }
  .popup__text {
    font-size: 1.6rem;
    line-height: 1.95rem;
  }
}

@media (max-width: 576px) {
  .popup__container {
    padding: 1.8rem;
    width: 33.6rem;
  }

  .popup__title {
    padding-top: 2.4rem;
    width: 66%;
  }
  .popup__text {
    font-size: 1.4rem;
    line-height: 1.7rem;
  }

  .popup__container .button {
    height: auto;
  }
}
