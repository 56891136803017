.builders {
  width: 109.6rem;
  margin: 4.8rem 0;
  display: flex;
  flex-direction: column;
  gap: 4.8rem;
}

.builders__container {
  display: flex;
  gap: 2.4rem;
}

.builders__image {
  width: 76rem;
  height: 46.5rem;
  object-fit: cover;
  object-position: center;
  border-radius: 3rem;
}

.builders__image-text {
  box-sizing: border-box;
  margin: 0;
  padding: 2.4rem;
  width: 31.2rem;
  background: rgba(45, 45, 45, 0.4);
  border-radius: 2.4rem;
  height: fit-content;
  color: #fff;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 2.2rem;
  text-align: left;
}

.builders__text {
  width: 100%;
  margin: 0;
  color: #fff;
  font-size: 2.4rem;
  font-weight: 400;
  line-height: 2.9rem;
  text-align: left;
}

@media (max-width: 1024px) {
  .builders {
    width: 100%;
    padding: 0 2.4rem;
    box-sizing: border-box;
    gap: 2.4rem;
  }

  .builders__image {
    width: 47.2rem;
    height: 28.9rem;
  }

  .builders__image-text {
    width: 22.4rem;
    font-size: 1.6rem;
    line-height: 1.95rem;
  }
}

@media (max-width: 576px) {
  .builders {
    margin: 2.4rem 0;
    padding: 0 1.2rem;
  }

  .builders__container {
    flex-direction: column;
  }

  .builders__image {
    width: 33.6rem;
    height: 20.5rem;
  }

  .builders__image-text {
    padding: 1.8rem;
    width: 100%;
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 1.95rem;
    text-align: center;
  }

  .builders__text {
    font-size: 1.6rem;
    line-height: 1.95rem;
  }
}
