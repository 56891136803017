.hall {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hall__link {
  display: none;
  width: 26rem;
  color: #fff;
  margin: 8rem 0 6.4rem;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 2rem;
  text-align: center;
  background-color: transparent;
  border: 0;
  text-decoration: underline;
}

.works {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.works__overlay {
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4rem;
  align-items: center;
  height: 34rem;
  background: linear-gradient(
    0deg,
    rgba(28, 28, 28, 1) 1%,
    rgba(0, 0, 0, 0) 80%
  );
}

.works__text {
  width: 76rem;
  margin: 0;
  color: #fff;
  font-size: 2.8rem;
  font-weight: 600;
  line-height: 3.4rem;
  text-align: center;
}

.work {
  height: 100dvh;
  position: sticky;
  display: flex;
  justify-content: center;
  top: 0;
  scroll-snap-align: start;
}

.work:not(:first-child) {
  box-shadow: 0px -0.8rem 0.8rem 0px rgba(0, 0, 0, 0.25);
}

/* Убираем тень у первого элемента .work */
.work:first-child {
  box-shadow: none;
}

.work::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background: linear-gradient(
    270deg,
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 0.8) 100%
  );
}

.work__image {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
}

.work__container {
  max-width: 144rem;
  width: 100%;
  box-sizing: border-box;
  padding: 15rem 6rem;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

.work__title {
  margin: 0;
  z-index: 2;
  color: #fff;
  font-size: 3.2rem;
  font-weight: 500;
  line-height: 3.9rem;
  text-align: left;
}

@media (min-width: 1441px) {
  .works__overlay {
    gap: 40px;
    height: 338px;
  }

  .works__text {
    width: 760px;
    font-size: 28px;
    line-height: 34.13px;
  }

  .work:not(:first-child) {
    box-shadow: 0px -8px 8px 0px rgba(0, 0, 0, 0.25);
  }

  .work__container {
    max-width: 1440px;
    padding: 150px 60px;
    gap: 12px;
  }

  .work__title {
    font-size: 32px;
    line-height: 39.01px;
  }
}

@media (max-width: 1024px) {
  .works__text {
    width: 62rem;
    font-size: 2.4rem;
    line-height: 2.9rem;
  }

  .work__container {
    margin: 19.7rem 6rem;
  }

  .work__title {
    font-size: 1.8rem;
    line-height: 2.2rem;
  }
}

@media (max-width: 576px) {
  .hall {
    padding: 0 1.2rem;
    box-sizing: border-box;
  }

  .hall__link {
    display: flex;
  }

  .works {
    gap: 4.8rem;
  }

  .works__overlay {
    display: none;
  }

  .work {
    height: fit-content;
    position: static;
    flex-direction: column;
    border-radius: 2.4rem;
    background-color: rgba(45, 45, 45, 0.4);
  }

  .work::before {
    display: none;
  }

  .work__image {
    position: relative;
    height: 31.2rem;
    border-radius: 2.4rem;
  }

  .work__container {
    margin: 0;
    padding: 1.2rem;
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
  }

  .work__title {
    font-size: 1.8rem;
    line-height: 2.2rem;
  }

  .work__title:first-child {
    margin-top: 1.2rem;
  }
}
