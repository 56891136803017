.team {
  margin: 9rem 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.team__container {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.persons {
  margin: 0;
  padding: 4.8rem 3.6rem;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  gap: 4.8rem;
  overflow-x: scroll;
  list-style: none;
  background-color: rgba(45, 45, 45, 0.4);
  scroll-snap-type: x mandatory;
}

.persons::-webkit-scrollbar {
  display: none;
}

.persons__button {
  padding: 1.2rem;
  display: none !important;
  box-sizing: border-box;
}

.persons__container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 7.5rem;
  flex-wrap: wrap;
  gap: 0.6rem;
}

.persons__image {
  aspect-ratio: 1/1;
  object-position: top;
  object-fit: cover;
  border-radius: 50%;
  background-color: rgba(154, 154, 154, 1);
}

.persons__link {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.3rem;
  width: 100%;
  color: #fff;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.46rem;
  text-align: center;
  cursor: pointer;
}

.person {
  padding: 0 24px;
  box-sizing: border-box;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  gap: 1.2rem;
  padding-bottom: 1.2rem;
  scroll-snap-align: center;
  cursor: pointer;
}

.person__image {
  width: 100%;
  object-fit: cover;
  object-position: top;
  aspect-ratio: 1/1;
  border-radius: 50%;
  background-color: rgba(154, 154, 154, 1);
}

.person__title {
  margin: 0;
  color: #fff;
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 2.9rem;
  text-align: left;
}

.person__subtitle {
  margin: 0;
  color: #fff;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 2.2rem;
  text-align: center;
}

.team__button {
  z-index: 1;
  padding: 0 6rem 0 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: flex-end !important;
  position: absolute !important;
  background: linear-gradient(
    90deg,
    rgba(35, 35, 35, 0) 0%,
    #232323 80%
  ) !important;
  border: 0;
  width: 21.4rem !important;
  height: 100% !important;
  top: 0;
  right: -1px !important;
  cursor: pointer;
  transition: all 0.2s linear;
}

.team__button::before {
  content: none !important;
}

.team__button_type_left {
  top: 0 !important;
  left: -1px !important;
  transform: rotate(-180deg) !important;
}

.team__info {
  margin: 6rem 0;
  width: 109.6rem;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

.team__text {
  margin: 0;
  width: 100%;
  color: #fff;
  font-size: 2.4rem;
  font-weight: 400;
  line-height: 2.9rem;
  text-align: left;
}

.drum {
  display: flex;
  align-items: center;
  gap: 2.4rem;
}

.drum__container {
  width: 42.4rem;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.drum__item {
  margin: 0;
  margin-top: 2.4rem;
  color: rgba(152, 152, 152, 1);
  font-size: 2.4rem;
  font-weight: 400;
  line-height: 2.9rem;
  text-align: center;
  cursor: pointer;
  transition: all 0.2s linear;
}

.drum__item_active {
  color: #fff;
  font-size: 3.2rem;
  font-weight: 500;
  line-height: 3.9rem;
  text-align: left;
}

.drum__button {
  padding: 0;
  background-color: transparent;
  border: 0;
  cursor: pointer;
}

.drum__button_type_down {
  transform: rotate(-180deg);
  margin-top: 2.4rem;
}

.area {
  margin: 2rem 0;
  height: 24rem !important;
  display: flex !important;
  align-items: center;
}

.area__container {
  padding: 2.4rem;
  box-sizing: border-box;
  width: 100%;
  height: fit-content;
  background-color: rgba(197, 197, 197, 1);
  border-radius: 2.4rem;
  display: flex !important;
  flex-wrap: wrap;
  gap: 2.4rem;
}

.area__text {
  width: 100%;
  margin: 0;
  color: #000;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 2.2rem;
}

.area__images {
  width: 100%;
  display: flex;
  gap: 1.2rem;
  height: fit-content;
}

.area__image {
  object-fit: cover;
  object-position: top;
  border-radius: 50%;
  background-color: #fff;
  width: 5rem !important;
  height: 5rem !important;
}

.drum__list {
  align-self: center;
  padding: 0;
  width: 42.4rem;
  height: 27.5rem;
}

.areas {
  padding: 0;
  width: 64.8rem;
  height: 27rem;
}

.drum__list .slick-list {
  padding-top: 4rem !important;
  height: 28rem !important;
  box-sizing: border-box !important;
}

.areas .slick-list {
  padding-top: 0 !important;
  margin-top: 0 !important;
  height: 28rem !important;
  box-sizing: border-box !important;
}

.slick-center .drum__item {
  color: #fff;
  font-size: 3.2rem;
  font-weight: 500;
  line-height: 3.9rem;
}

@media (min-width: 1441px) {
  .team {
    margin: 90px 0;
  }

  .persons {
    padding: 48px 0;
    gap: 48px;
  }

  .person {
    width: 238px;
    gap: 12px;
    padding-bottom: 12px;
  }

  .person__title {
    font-size: 24px;
    line-height: 29.26px;
  }

  .person__subtitle {
    font-size: 18px;
    line-height: 22px;
  }

  .team__button {
    width: 214px !important;
  }

  .team__info {
    margin: 60px 0;
    width: 1096px;
    gap: 12px;
  }

  .team__text {
    font-size: 24px;
    line-height: 29.26px;
  }

  .drum {
    gap: 24px;
  }

  .drum__container {
    width: 424px;
  }

  .drum__item {
    margin-top: 24px;
    font-size: 24px;
    line-height: 29.26px;
  }

  .drum__item_active {
    font-size: 32px;
    line-height: 39.01px;
  }

  .drum__button_type_down {
    margin-top: 24px;
  }

  .area {
    margin: 20px 0;
    height: 240px !important;
  }

  .area__text {
    font-size: 18px;
    line-height: 21.94px;
  }

  .area__images {
    gap: 12px;
  }

  .area__image {
    width: 50px;
    height: 50px;
  }

  .drum__list {
    width: 424px;
    height: 275px;
  }

  .areas {
    width: 648px;
  }

  .drum__list .slick-list {
    padding-top: 40px !important;
    height: 280px !important;
  }

  .areas .slick-list {
    height: 280px !important;
  }

  .slick-center .drum__item {
    font-size: 32px;
    line-height: 39.01px;
  }
}

@media (max-width: 1024px) {
  .team {
    margin: 0;
  }
  .team__info {
    margin: 4.8rem 0;
    width: 72rem;
  }

  .team__text {
    font-size: 1.6rem;
    line-height: 1.95rem;
  }

  .persons {
    padding: 2.4rem 1.2rem;
  }

  .team__button {
    padding: 0 2.4rem 0 !important;
    width: 12.4rem !important;
    background: linear-gradient(
      90deg,
      rgba(35, 35, 35, 0) 0%,
      #232323 90%
    ) !important;
  }

  .team__drum {
    width: 100%;
  }

  .person {
    padding: 0 1.2rem;
    gap: 0.5rem;
  }

  .person__title {
    font-size: 1.8rem;
    line-height: 2.2rem;
  }

  .person__subtitle {
    font-size: 1.2rem;
    line-height: 1.46rem;
  }

  .drum {
    flex-direction: column;
    padding: 0 0 2.4rem;
  }

  .drum__item {
    width: fit-content !important;
  }

  .drum__container {
    width: 72rem;
    flex-direction: row;
    align-items: center;
    gap: 1.2rem;
  }

  .drum__button {
    transform: rotate(-90deg);
  }

  .drum__button_type_down {
    margin: 0;
    transform: rotate(90deg);
  }

  .drum__list {
    padding: 0;
    width: 64.8rem;
    height: 1.95rem;
  }

  .drum__list .slick-list {
    padding-top: 0 !important;
    height: auto !important;
    box-sizing: content-box !important;
  }

  .drum__item {
    padding: 0 1.2rem;
    margin: 0;
    font-size: 1.6rem;
    line-height: 1.95rem;
  }

  .slick-current .drum__item {
    color: #fff;
    font-size: 1.6rem;
    line-height: 1.95rem;
  }

  .area {
    width: 100%;
    margin: 0;
    padding: 0 2.4rem;
    box-sizing: border-box;
    align-items: start;
    height: 20rem !important;
  }

  .area__container {
    gap: 1.5rem;
  }

  .area__text {
    font-size: 1.4rem;
    line-height: 1.7rem;
  }

  .areas {
    width: 100%;
    height: 20rem;
  }

  .areas .slick-list {
    height: 20rem !important;
  }
}

@media (max-width: 576px) {
  .team__drum {
    width: 100%;
  }

  .team__button {
    padding: 0.6rem !important;
    width: 3.6rem !important;
    height: 3.6rem !important;
    background: rgba(45, 45, 45, 0.4) !important;
    border-radius: 50%;
    top: 50% !important;
    right: 1.2rem !important;
    transform: translate(0, -50%);
  }

  .slick-disabled {
    opacity: 0;
  }

  .team__button_type_left {
    left: 1.2rem !important;
    transform: translate(0, -50%) rotate(-180deg) !important;
  }

  .team__image {
    width: 2.4rem;
    height: 2.4rem;
  }

  .drum__button {
    display: none;
  }

  .team__info {
    width: 33.6rem;
  }

  .drum__list {
    width: 100%;
  }

  .areas {
    width: 100%;
    height: 28rem !important;
  }

  .areas .slick-list {
    height: 29rem !important;
  }

  .drum__container {
    width: 100%;
    overflow-x: hidden;
  }

  .area {
    padding: 0 1.2rem;
    width: 33.6rem;
    height: 28rem !important;
  }

  .area__images {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
  }

  .area__image {
    width: 100% !important;
    height: auto !important;
    aspect-ratio: 1/1;
  }

  .area__container {
    width: 100%;
  }

  .persons {
    padding: 1.2rem 0;
  }

  .persons__button {
    display: flex !important;
  }

  .person {
    height: 24.2rem;
    padding: 1.2rem;
    gap: 0.5rem;
  }
}
