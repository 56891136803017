.about {
  margin-bottom: 4.6rem;
  width: 144rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about__promo {
  position: relative;
  height: 48.5rem;
  width: 100vw;
  background-color: rgba(217, 217, 217, 1);
  display: flex;
  justify-content: center;
  align-items: end;
  overflow: hidden;
}

.about__promo::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  /* background: linear-gradient(180deg, rgba(57, 57, 57, 0) 70%, #1a1a1a 100%); */
  background-color: rgba(0, 0, 0, 0.4);
}

.about__image {
  position: absolute;
  top: -8rem;
  object-fit: contain;
  width: 100%;
  max-width: 144rem;
}

.about__title {
  margin: 0;
  width: 100%;
  padding: 5rem 0;
  color: #fff;
  font-size: 6rem;
  font-weight: 500;
  line-height: 8rem;
  z-index: 3;
  display: flex;
  justify-content: center;
}

.about__columns {
  margin-top: 14rem;
  margin-bottom: 8rem;
  width: 97.8rem;
  display: grid;
  gap: 4.8rem;
  grid-template-columns: repeat(3, 1fr);
}

.about__column {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4.8rem;
}

.about__column:nth-child(2) {
  margin-top: 16rem;
}

.about__person {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

.about__person-image {
  width: 100%;
  height: 44rem;
  background-color: rgba(154, 154, 154, 1);
  border-radius: 2.4rem;
  object-position: 0 0 -4px;
}

.about__person-title {
  margin: 0;
  color: #fff;
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 2.9rem;
  text-align: center;
}

.about__person-subtitle {
  margin: 0;
  color: #fff;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.8rem;
  text-align: center;
}

@media (min-width: 1441px) {
  .about__promo {
    width: 100vw;
  }
}

@media (max-width: 1024px) {
  .about {
    width: 100%;
  }

  .about__promo {
    height: 30.2rem;
  }

  .about__title {
    padding: 1.2rem 0;
    font-size: 4rem;
    line-height: 8rem;
  }

  .about__image {
    top: 0;
  }

  .about__person-title {
    font-size: 2rem;
    line-height: 2.4rem;
  }

  .about__person-subtitle {
    font-size: 1.4rem;
    line-height: 1.8rem;
  }

  .about__person-image {
    height: 33.5rem;
  }

  .about__columns {
    margin-top: 4.8rem;
    padding: 0 2.4rem;
    gap: 2.4rem;
    box-sizing: border-box;
    width: 100%;
  }

  .about__column {
    gap: 2.4rem;
  }

  .about__slider {
    width: 100%;
  }
}

@media (max-width: 576px) {
  .about__promo {
    height: 15rem;
    background-color: transparent;
  }

  .about__promo::after {
    display: none;
  }

  /* .promo__gradient {
    display: none;
  } */

  .about__title {
    font-size: 3rem;
    line-height: 3rem;
  }

  .about__image {
    display: none;
  }

  .about__columns {
    margin-top: 2.4rem;
    padding: 0 7.8rem;
    display: flex;
    flex-direction: column;
  }

  .about__person-image {
    height: 30.5rem;
  }
}
