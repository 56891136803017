.price {
  margin: 9rem 0;
  padding: 0 6rem;
  box-sizing: border-box;
  width: 100%;
  max-width: 144rem;
  display: flex;
  gap: 2.4rem;
}

.price__form {
  width: 42.4rem;
}

.price__image {
  flex-grow: 1;
  width: 100%;
  height: 62rem;
  max-width: 87.2rem;
  border-radius: 2.4rem;
  object-fit: cover;
}

@media (min-width: 1441px) {
  .price {
    margin: 90px 0;
    padding: 0 60px;
    max-width: 1440px;
    gap: 24px;
  }

  .price__form {
    width: 424px;
  }
  
  .price__image {
    max-width: 872px;
    height: 620px;
    border-radius: 24px;
  }
}

@media (max-width: 1024px) {
  .price {
    margin: 2.4rem 0;
    padding: 0 2.4rem;
    flex-direction: column;
  }

  .price__form {
    width: 100%;
  }

  .price__image {
    order: -1;
    height: 32.4rem;
  }
}

@media (max-width: 576px) {
  .price {
    padding: 0 1.2rem;
  }
}