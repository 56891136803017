.header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 8rem;
  z-index: 4;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s linear;
  background-color: rgba(45, 45, 45, 0.4);
}

.header::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(10px);
  z-index: -1;
}

.header__container {
  padding: 1.8rem 6rem;
  box-sizing: border-box;
  width: 100%;
  max-width: 144rem;
  display: flex;
  justify-content: space-between;
  transition: all 0.2s linear;
}

.header__navigation {
  display: flex;
  gap: 3rem;
}

.header__bin {
  display: flex;
  gap: 3rem;
  align-items: center;
}

.header__contacts {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header__link {
  width: fit-content;
  color: #fff;
  text-decoration: none;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.7rem;
  text-align: left;
}

.header__link_type_mail {
  color: rgba(192, 192, 192, 1);
}

.header__burger-button {
  background-size: contain;
  display: none;
  padding: 0;
  width: 24px;
  height: 24px;
  align-self: center;
  background-image: url("../../images/Burger/burger-button.svg");
  background-repeat: no-repeat;
  background-color: transparent;
  background-position: center center;
  border: 0;
  cursor: pointer;
  transition: all 0.1s linear;
}

@media (min-width: 1441px) {
  .header {
    height: 80px;
  }

  .header__container {
    padding: 18px 60px;
    max-width: 1440px;
  }

  .header__navigation {
    gap: 30px;
  }

  .header__bin {
    gap: 30px;
  }

  .header__link {
    font-size: 14px;
    line-height: 17px;
  }
}

@media (max-width: 1024px) {
  .header__navigation {
    display: none;
  }
  .header__burger-button {
    display: block;
  }

  .header {
    height: 7.2rem;
  }

  .header__container {
    padding: 1.5rem 2.4rem;
  }

  .header__burger-button {
    width: 2.4rem;
    height: 2.4rem;
  }
}

@media (max-width: 576px) {
  .header__container {
    padding: 1.5rem 1.2rem;
  }
}
