@keyframes gradientFade {
  0% {
    transform: translateX(0%);
  }
  20% {
    transform: translateX(15%);
  }
  100% {
    transform: translateX(60%);
  }
}

@keyframes gradientFadePlan {
  0% {
    transform: translateX(-20%);
  }
  20% {
    transform: translateX(-20%);
  }
  70% {
    transform: translateX(15%);
  }
  100% {
    transform: translateX(60%);
  }
}
@keyframes gradientFadeTel {
  0% {
    transform: translateX(-40%);
  }
  20% {
    transform: translateX(-40%);
  }
  99% {
    transform: translateX(15%);
  }
  100% {
    transform: translateX(60%);
  }
}

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(28, 28, 28, 1);
  z-index: 6;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.4s ease; /* Переход на изменение opacity */
  opacity: 1;
}

.preloader_hidden {
  opacity: 0;
  pointer-events: none;
}

.preloader__image {
  width: 14%;
}

.preloader__container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.preloader__overlay {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  z-index: 7;
  animation: gradientFade 4s infinite;
}

@media (max-width: 1024px) {
  .preloader__image {
    width: 22.2rem;
  }

  .preloader__overlay {
    animation: gradientFadePlan 4s infinite;
  }
}

@media (max-width: 576px) {
  .preloader__image {
    width: 20.2rem;
  }

  .preloader__overlay {
    animation: gradientFadeTel 4s infinite;
  }
}
