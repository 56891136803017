.directions {
  margin-bottom: 6rem;
  width: 100%;
  max-width: 144rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.directions__container {
  padding: 0 6rem;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  transition: all 0.2s linear;
}

.direction {
  padding: 2.4rem;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 500px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right 0 bottom 35%;
  border-radius: 4.8rem;
  display: flex;
  overflow: hidden;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s linear;
}

.direction:hover .direction__image {
  transform: scale(1.1);
}

.direction_type_second {
  background-position: right 0 bottom 50%;
  justify-content: flex-end;
}

.direction__image {
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 4.8rem;
  width: 100%;
  height: 100%;
  object-position: 0 -22rem;
  transition: all 0.4s ease-out;
}

.direction__container {
  position: relative;
  padding: 3rem 4rem;
  width: fit-content;
  height: fit-content;
  background-color: #2d2d2d66;
  backdrop-filter: blur(15px);
  border-radius: 2.4rem;
  box-sizing: border-box;
  z-index: 1;
  transition: all 0.2s linear;
}

.direction__container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 2.4rem;
  padding: 0.1rem;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.16) 0%,
    rgba(102, 102, 102, 0.16) 100%
  );
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
  z-index: -1;
  transition: all 0.2s linear;
}

.direction__title {
  width: 28.1rem;
  margin: 0;
  color: #fff;
  font-size: 3.2rem;
  font-weight: 500;
  line-height: 3.9rem;
  text-align: center;
  transition: all 0.2s linear;
}

.direction__title_type_second {
  width: 34.4rem;
}

@media (min-width: 1441px) {
  .directions {
    max-width: 1440px;
  }

  .directions {
    margin-bottom: 60px;
  }

  .directions__container {
    padding: 0 60px;
    gap: 24px;
  }

  .direction {
    padding: 24px;
    height: 500px;
    border-radius: 48px;
  }

  .direction__image {
    border-radius: 48px;
    object-position: 0 -220px;
  }

  .direction__container {
    padding: 30px 40px;
    border-radius: 24px;
  }

  .direction__container::before {
    border-radius: 24px;
    padding: 1px;
  }

  .direction__title {
    width: 281px;
    font-size: 32px;
    line-height: 39.01px;
  }

  .direction__title_type_second {
    width: 344px;
  }
}

@media (max-width: 1024px) {
  .directions__container {
    padding: 0 2.4rem;
  }

  .direction__image {
    border-radius: 4.8rem;
    object-position: center;
  }

  .direction__container {
    padding: 2rem 3rem;
  }

  .direction__title {
    width: auto;
    font-size: 1.8rem;
    line-height: 2.2rem;
  }

  .direction__title_type_second {
    width: 26rem;
  }
}

@media (max-width: 576px) {
  .directions__container {
    padding: 0 1.2rem;
  }

  .direction {
    aspect-ratio: 1/1;
    height: auto;
  }

  .direction__image {
    object-position: -12rem;
  }

  .direction__title_type_second {
    width: 100%;
  }
}
