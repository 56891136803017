.popup-image {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-image__container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  gap: 2rem;
  width: 100%;
  height: 100%;
}

.image-popup-image {
  max-width: 76vw;
  max-height: 86vh;
  object-fit: cover;
}

.image-popup-image_type_big {
  max-width: 80vw;
  max-height: 90vh;
}

.image-popup-button {
  border-radius: 50%;
  padding: 1.2rem;
  position: absolute;
  background-color: rgba(45, 45, 45, 0.4);
  border: none;
  height: fit-content;
  color: white;
  font-size: 2rem;
  cursor: pointer;
  display: flex;
  z-index: 2;
}

.popup-image__close-button {
  padding: 0;
  border-radius: 50%;
  background: rgba(45, 45, 45, 0.4);
  width: 7.2rem;
  height: 7.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  position: absolute;
  top: 7.2rem;
  right: 7.2rem;
  cursor: pointer;
}

.popup-image__close-button-image {
  width: 4.8rem;
  height: 4.8rem;
}

.image-popup-button--prev {
  left: 6rem;
  transform: rotate(-180deg);
}

.image-popup-button--next {
  right: 6rem;
}

.popup-image__arrow {
  width: 2.4rem;
  height: 2.4rem;
}

.image-popup-indicator {
  padding: 1.2rem;
  background-color: rgba(45, 45, 45, 0.4);
  position: absolute;
  border-radius: 12px;
  bottom: 6rem;
  color: white;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 2.2rem;
}

@media (max-width: 1024px) {
  .image-popup-image {
    max-height: 80vh;
  }

  .image-popup-image_type_big {
    max-width: 100vw;
    max-height: 85vh;
  }

  .image-popup-button--prev {
    left: 2.4rem;
  }

  .image-popup-button--next {
    right: 2.4rem;
  }

  .image-popup-indicator {
    bottom: 2.4rem;
  }

  .popup-image__close-button {
    width: 4.8rem;
    height: 4.8rem;
    top: 2.4rem;
    right: 2.4rem;
  }

  .popup-image__close-button-image {
    width: 2.4rem;
    height: 2.4rem;
  }
}

@media (max-width: 576px) {
  .image-popup-image {
    max-width: 95vw;
    max-height: 80vh;
  }

  .image-popup-image {
    max-width: 100vw;
  }

  .image-popup-button--prev {
    left: 2.4rem;
  }

  .image-popup-button--next {
    right: 2.4rem;
  }

  .image-popup-indicator {
    bottom: 2.4rem;
  }
}
