.aparts {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  scroll-snap-type: y mandatory;
}

.service__question {
  margin: 8rem 0;
  width: 109.6rem;
  display: flex;
  flex-direction: column;
  gap: 4.6rem;
}

.service__question-title {
  margin: 0;
  color: #fff;
  font-size: 4.5rem;
  font-weight: 500;
  line-height: 5.5rem;
  text-align: left;
}

.service__answer {
  margin-top: -2.4rem;
  display: flex;
  flex-direction: column;
}

.service__answer-container {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

.servise__answer-title {
  margin: 0;
  color: #fff;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 2.2rem;
  text-align: left;
}

.servise__answer-text {
  list-style: none;
  color: rgba(152, 152, 152, 1);
}

.mon {
  width: 109.6rem;
  display: flex;
  flex-direction: column;
  gap: 4.8rem;
}

.mon__title {
  color: #fff;
  margin: 0;
  font-weight: 500;
  font-size: 3.2rem;
  line-height: 4.2rem;
}

.mon__container {
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
}

.mon__subtitle {
  color: #fff;
  margin: 0;
  font-weight: 500;
  font-size: 2.4rem;
  line-height: 2.9rem;
}

.mon__text {
  color: #989898;
  margin: 0;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 2.4rem;
}

.mon__list {
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
}

.mon__point {
  color: #989898;
  margin: 0;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 2.4rem;
}

.service__videos {
  width: 132rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2.4rem;
  align-self: center;
}

.design {
  margin: 8rem 0 4.6rem;
  width: 109.6rem;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
}

.design__title {
  margin: 0;
  color: #fff;
  font-size: 4.5rem;
  font-weight: 500;
  line-height: 5.5rem;
  text-align: left;
}

.design__text {
  margin: 0;
  color: #fff;
  font-size: 2.8rem;
  font-weight: 400;
  line-height: 4.2rem;
  text-align: left;
}

.design__container {
  display: grid;
  grid-template-columns: 40.2rem 67rem;
  gap: 2.4rem;
}

.design__column {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2.4rem;
  list-style: none;
}

.design__element {
  margin: 0;
  color: #fff;
  padding: 1.2rem;
  border-radius: 1.2rem;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 1.2rem;
  font-size: 2.4rem;
  font-weight: 400;
  line-height: 2.9rem;
  box-sizing: border-box;
  background-color: transparent;
  cursor: pointer;
  transition: all 0.2s linear;
}

.design__element_active {
  background-color: rgba(255, 255, 255, 0.2);
}

.design__number {
  margin: 0;
  color: rgba(33, 33, 33, 1);
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 2.9rem;
  width: 3.9rem;
  height: 3.9rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 50%;
}

.design__number_active {
  color: rgba(230, 0, 126, 1);
}

.plans {
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 2.4rem;
  overflow: hidden;
  height: fit-content;
}

.design__slider-button {
  border-radius: 50%;
  padding: 1.2rem;
  position: absolute;
  background-color: rgba(45, 45, 45, 0.4);
  border: none;
  height: fit-content;
  color: white;
  font-size: 2rem;
  cursor: pointer;
  display: flex;
  z-index: 2;
}

.design__slider-button--prev {
  left: 2.4rem;
  transform: rotate(-180deg);
}

.design__slider-button--next {
  right: 2.4rem;
}

.design__image {
  width: 67rem;
  height: 48.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.design__image img {
  width: fit-content;
  max-width: 100%;
  height: fit-content;
  max-height: 100%;
  object-fit: contain;
  border-radius: 2.4rem;
  overflow: hidden;
}

.design__slider-index {
  margin: 0;
  padding: 1.2rem;
  background-color: rgba(45, 45, 45, 0.4);
  position: absolute;
  border-top-right-radius: 1.2rem;
  border-bottom-left-radius: 2.4rem;
  left: 0;
  bottom: 0;
  color: white;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 2.2rem;
}

.design__slider {
  position: relative;
  transition: opacity 0.3s ease-in-out;
  opacity: 1;
}

.design__slider.hidden {
  opacity: 0;
}
.design__slider.visible {
  opacity: 1;
}

.factors {
  width: 132rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 8rem;
}

.factors__title {
  padding: 4.8rem 6rem;
  margin: 0;
  color: #fff;
  font-size: 4.5rem;
  font-weight: 500;
  line-height: 5.5rem;
  text-align: left;
}

.factors__list {
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2.4rem;
  grid-template-rows: 22.3rem 22.3rem 22.3rem;
  list-style: none;
}

.factors__element {
  margin: 0;
  width: 100%;
  padding: 2.4rem;
  background-color: rgba(45, 45, 45, 0.4);
  color: #fff;
  font-size: 2.4rem;
  font-weight: 400;
  line-height: 2.9rem;
  text-align: left;
  box-sizing: border-box;
  border-radius: 2.4rem;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
}

.factors__number {
  color: rgba(140, 0, 68, 1);
  font-size: 5rem;
  font-weight: 500;
  line-height: 4rem;
  text-align: left;
}

@media (max-width: 1024px) {
  .service__question {
    padding: 2.4rem;
    width: 100%;
    box-sizing: border-box;
  }

  .service__question-title {
    font-size: 3.2rem;
    line-height: 3.9rem;
  }

  .service__videos {
    padding: 0 2.4rem;
    width: calc(100% - 4.8rem);
  }

  .mon {
    width: 100%;
    padding: 0 2.4rem;
    box-sizing: border-box;
    gap: 3.6rem;
  }

  .mon__title {
    font-size: 2.4rem;
    line-height: 2.9rem;
  }

  .mon__subtitle {
    font-size: 2rem;
    line-height: 2.4rem;
  }

  .mon__text {
    font-size: 1.6rem;
    line-height: 1.95rem;
  }

  .mon__list {
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
  }

  .mon__point {
    font-size: 1.6rem;
    line-height: 1.95rem;
  }

  .design {
    margin: 4.8rem 0;
    width: 100%;
    padding: 0 2.4rem;
    box-sizing: border-box;
  }

  .design__container {
    grid-template-columns: 25.2rem 44.4rem;
  }

  .design__image {
    width: 44.4rem;
    height: 30rem;
  }

  .design__column {
    gap: 1.2rem;
  }

  .design__element {
    font-size: 1.8rem;
    line-height: 2.2rem;
  }

  .design__number {
    font-size: 2rem;
    line-height: 2.4rem;
    min-width: 3.9rem;
    height: 3.9rem;
  }

  .design__slider-button {
    padding: 0;
    width: 3.6rem;
    height: 3.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .factors {
    width: 100%;
    padding: 0 2.4rem;
    box-sizing: border-box;
    margin-bottom: 4.8rem;
  }

  .factors__title {
    font-size: 3.2rem;
    line-height: 3.9rem;
  }

  .factors__list {
    gap: 1.2rem;
    grid-template-rows: 20.8rem 20.8rem 20.8rem;
  }

  .factors__element {
    text-wrap: wrap;
    font-size: 2rem;
    line-height: 2.4rem;
    hyphens: auto;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
  }
}

@media (max-width: 576px) {
  .service__question-title {
    font-size: 2.4rem;
    line-height: 2.9rem;
  }

  .service__videos {
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
  }

  .mon {
    padding: 0 1.2rem;
    gap: 2.4rem;
  }

  .mon__container {
    gap: 1.2rem;
  }

  .mon__title {
    font-size: 2rem;
    line-height: 2.4rem;
  }

  .mon__subtitle {
    font-size: 1.8rem;
    line-height: 2.2rem;
  }

  .mon__text {
    font-size: 1.4rem;
    line-height: 1.7rem;
  }

  .mon__list {
    gap: 1.2rem;
  }

  .mon__point {
    font-size: 1.4rem;
    line-height: 1.7rem;
  }

  .design {
    margin: 2.4rem 0 4.8rem;
    padding: 0 1.2rem;
  }

  .design__title {
    font-size: 2.4rem;
    line-height: 2.9rem;
  }

  .design__text {
    font-size: 1.8rem;
    line-height: 2.2rem;
  }

  .design__container {
    display: flex;
    flex-direction: column;
  }

  .design__element {
    padding: 1.2rem 0;
    display: flex !important;
    font-size: 1.6rem;
    line-height: 1.95rem;
  }

  .design__element_active {
    background-color: transparent;
  }

  .service__question {
    padding: 0 1.2rem;
    margin: 4.2rem 0 4.8rem;
  }

  .plans .slick-list {
    width: 33.6rem;
  }

  .design__image {
    border-radius: 2.4rem;
    overflow: hidden;
    margin: 1.8rem 0 5.3rem;
    width: 33.6rem;
    height: 23.6rem;
  }

  .design__slider-index {
    border-bottom-left-radius: 0.6rem;
    bottom: 5.3rem;
  }

  .design__slider-button {
    display: none;
  }

  .factors__title {
    padding: 2.4rem 0;
    font-size: 2.4rem;
    line-height: 2.9rem;
  }

  .factors__list {
    display: flex;
    flex-direction: column;
    hyphens: none;
    -webkit-hyphens: none;
    -ms-hyphens: nones;
  }

  .factors__element {
    width: 27.6rem;
    padding: 1.8rem;
    flex-direction: row;
    align-items: center;
    font-size: 1.6rem;
    line-height: 1.95rem;
  }

  .factors__element:nth-child(2n) {
    align-self: end;
  }

  .factors__number {
    font-size: 4.4rem;
    line-height: 4rem;
  }

  .servise__answer-title {
    font-size: 1.6rem;
  }
}
