@keyframes burgerOpen {
  from {
    transform: translateX(+100%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes burgerClose {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(+150%);
  }
}

@keyframes overlayOpen {
  from {
    opacity: 0;
    visibility: hidden;
  }

  to {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes overlayClose {
  from {
    opacity: 1;
    visibility: visible;
  }

  to {
    opacity: 0;
    visibility: hidden;
  }
}

.burger {
  position: fixed;
  z-index: 2;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  justify-content: flex-end;
  display: none;
}

.burger__overlay {
  position: fixed;
  height: 100dvh;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
}

.burger__container {
  padding: 12px 24px;
  box-sizing: border-box;
  position: relative;
  width: 330px;
  height: 100dvh;
  z-index: 4;
  background-color: #1c1c1c;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.burger__middle {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.burger__header {
  box-sizing: border-box;
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.burger__logo {
  display: none;
}

.burger__but {
  margin: 20px 0;
  color: #fff;
  width: fit-content;
  font-size: 20px;
  line-height: 1.95rem;
  padding: 0;
  background-color: transparent;
  border: 0;
  text-decoration: underline;
}

.burger__close-button {
  padding: 0;
  background-image: url(../../images/Burger/burger-close-button.svg);
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
  border: 0;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.burger__footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.burger__nav {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.burger__link {
  font-family: "Montserrat", Arial, sans-serif;
  margin: 12px 0;
  width: 100%;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  text-wrap: wrap;
  text-decoration: none;
}

.burger__link:first-child {
  line-height: 30px;
}

.burger__socials {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  gap: 12px;
  height: fit-content;
}

.burger__social {
  width: 100%;
  max-width: 48px;
}

.burger__social-image {
  width: 100%;
}

.burger__policy {
  font-family: "Montserrat", Arial, sans-serif;
  color: #989898;
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.07px;
  text-align: left;
  text-wrap: wrap;
}

@media (max-width: 1024px) {
  .burger {
    display: flex;
  }
  .burger__overlay_active {
    animation: overlayOpen 0.6s forwards;
  }
  .burger__overlay_deactive {
    animation: overlayClose 0.6s forwards;
  }
  .burger__container_active {
    animation: burgerOpen 0.6s backwards;
  }
  .burger__container_deactive {
    animation: burgerClose 0.6s backwards;
  }
}

@media (max-width: 576px) {
  .burger__container {
    padding: 12px;
    width: 100%;
  }

  .burger__header {
    justify-content: space-between;
  }

  .burger__logo {
    display: flex;
  }

  .burger__link {
    margin: 0;
  }

  .burger__link_type_mail {
    display: none;
  }

  /* .burger__nav {
    display: none;
  } */
}
