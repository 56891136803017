@keyframes answerOpen {
  0% {
    height: 0;
  }
  100% {
    height: 100%;
  }
}

@keyframes answerClose {
  0% {
    height: 100%;
    display: flex;
  }
  100% {
    height: 0;
    display: none;
  }
}

.questions {
  width: 100%;
  max-width: 87.2rem;
  margin: 6rem 0;
  box-sizing: border-box;
  width: calc(100% - 34.4rem);
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

.questions__title {
  margin: 0;
  margin-bottom: 1.8rem;
  color: #fff;
  font-size: 4.5rem;
  font-weight: 500;
  line-height: 5.5rem;
  text-align: center;
}

.questions__container {
  flex-direction: column;
  box-sizing: border-box;
  gap: 2rem;
  padding: 2.4rem;
  width: 100%;
  border-radius: 2.4rem !important;
  background-color: rgba(45, 45, 45, 0.4);
  transition: all 0.7s linear;
  margin-bottom: 1.2rem;
  border: 0 !important;
}

.rs-panel::before {
  content: none !important;
}

.rs-panel-header {
  padding: 0 !important;
}

.rs-panel-btn {
  background-color: transparent !important;
  padding: 0 !important;
}

.rs-panel-btn-icon {
  display: none;
}

.rs-panel-body {
  padding: 0 !important;
}

.questions__container_active {
  max-height: 300rem;
}

.questions__title-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.question__open-image {
  object-fit: cover;
  width: 3rem;
  height: 3rem;
  transition: all 0.2s linear;
}

.questions__open-image_active {
  transform: rotate(-45deg);
}

.question__question {
  width: calc(100% - 6rem);
  color: #fff;
  margin: 0;
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 2.9rem;
  text-align: start;
  transition: all 0.2s linear;
}

.questions__button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  background-color: transparent;
  width: 3.9rem;
  height: 3.9rem;
  margin-left: auto;
  background-color: rgba(45, 45, 45, 0.4);
  transition: all 0.2s linear;
}

.questions__answer {
  margin: 2.4rem 0 0;
  color: #fff;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 2.2rem;
  transition: all 0.2s linear;
}

.question__list {
  margin-top: 2.4rem;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
}

.question__point {
  color: #fff;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 2.4rem;
}

.questions__answer_active {
  animation: answerOpen 0.2s forwards;
}

.questions__answer_disactive {
  display: none;
  animation: answerClose 0.2s forwards;
}

@media (min-width: 1441px) {
  .questions {
    max-width: 1096px;
    margin: 60px 0;
    width: calc(100% - 344px);
    gap: 12px;
  }

  .questions__title {
    margin-bottom: 18px;
    font-size: 45px;
    line-height: 54.86px;
  }

  .questions__container {
    gap: 20px;
    padding: 24px;
    border-radius: 24px;
  }

  .question__open-image {
    width: 30px;
    height: 30px;
  }

  .question__question {
    width: calc(100% - 60px);
    font-size: 24px;
    line-height: 29.26px;
  }

  .questions__button {
    width: 39px;
    height: 39px;
  }

  .questions__answer {
    font-size: 18px;
    line-height: 21.94px;
  }
}

@media (max-width: 1024px) {
  .questions {
    margin: 2.4rem 0;
    width: 100%;
    padding: 0 2.4rem;
  }

  .questions__title {
    font-size: 3.2rem;
    line-height: 3.9rem;
    text-align: center;
  }

  .questions__container {
    padding: 1.8rem;
  }

  .questions__container_active {
    max-height: 400rem;
  }

  .question__question {
    font-size: 2rem;
    line-height: 2.4rem;
  }

  .questions__answer {
    font-size: 1.6rem;
    line-height: 1.95rem;
  }

  .question__list {
    padding-left: 3.2rem;
    margin-top: 1.6rem;
    gap: 1.6rem;
  }

  .question__point {
    font-size: 1.6rem;
    line-height: 1.95rem;
  }
}

@media (max-width: 576px) {
  .questions {
    padding: 0 1.2rem;
  }

  .questions__title {
    margin-bottom: 1.2rem;
    font-size: 3.2rem;
    line-height: 3.9rem;
  }

  .question__question {
    width: calc(100% - 4.2rem);
    font-size: 1.6rem;
    line-height: 1.95rem;
  }

  .questions__answer {
    font-size: 1.4rem;
    line-height: 1.7rem;
  }

  .question__list {
    padding-left: 2.4rem;
    margin-top: 1.4rem;
    gap: 1.4rem;
  }

  .question__point {
    font-size: 1.4rem;
    line-height: 1.7rem;
  }

  .question__open-image {
    width: 1.7rem;
    height: 1.7rem;
  }
}
