.feedbacks {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.feedbacks__title {
  margin: 0;
  margin-bottom: 3rem;
  color: #fff;
  font-size: 4.5rem;
  font-weight: 500;
  line-height: 5.5rem;
  text-align: center;
}

.feedbacks__container {
  width: 100%;
  display: flex !important;
  flex-direction: column;
  align-items: center;
}

.feedback {
  padding: 0 1.2rem;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.feedback__text {
  margin: 0;
  position: relative;
  padding: 2.4rem;
  background-color: #232323;
  color: #fff;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 2.2rem;
  z-index: 1;
  text-align: left;
  border-radius: 2.4rem;
  box-sizing: border-box;
}

.feedback__text::before {
  z-index: 0;
  content: "";
  width: 2.4rem;
  height: 2.4rem;
  position: absolute;
  bottom: -2.3rem;
  left: 2.4rem;
  background-color: #232323;
  clip-path: polygon(0 0, 0 100%, 100% 0);
}

.feedback__title {
  margin: 0;
  margin-top: 3.6rem;
  color: #fff;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 2.2rem;
  text-align: left;
}

.feedback__subtitle {
  margin: 0;
  margin-top: 0.4rem;
  color: #989898;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.7rem;
  text-align: left;
}

.feedbacks__container .slick-list {
  width: calc(100% - 8rem);
}

.feedbacks__container .slick-dots {
  margin-top: 3rem;
  position: static;
  display: flex !important;
  justify-content: center;
  width: fit-content;
  gap: 0.2rem;
}

.feedbacks__container .slick-dots li button:before {
  color: #595959;
}

.feedbacks__container .slick-dots .slick-active button::before{
  color: #fff;
}

@media (min-width: 1441px) {
  .feedbacks__title {
    margin-bottom: 30px;
    font-size: 45px;
    line-height: 54.86px;
  }
  
  .feedback {
    padding: 0 12px;
  }
  
  .feedback__text {
    padding: 24px;
    font-size: 18px;
    line-height: 21.94px;
    border-radius: 24px;
  }
  
  .feedback__text::before {
    width: 24px;
    height: 24px;
    bottom: -23px;
    left: 24px;
  }
  
  .feedback__title {
    margin-top: 36px;
    font-size: 18px;
    line-height: 21.94px;
  }
  
  .feedback__subtitle {
    margin-top: 4px;
    font-size: 14px;
    line-height: 17.07px;
  }
  
  .feedbacks__container .slick-list {
    width: calc(100% - 80px);
  }
  
  .feedbacks__container .slick-dots {
    margin-top: 30px;
    gap: 2px;
  }
}

@media (max-width: 1024px) {
  .feedbacks__title {
    font-size: 3.2rem;
    line-height: 3.9rem;
  }

  .feedbacks__container .slick-list {
    width: calc(100% - 2.4rem);
  }

  .feedback__text {
    font-size: 1.4rem;
    line-height: 1.7rem;
  }

  .feedback__title {
    font-size: 1.4rem;
    line-height: 1.7rem;
  }

  .feedback__subtitle {
    font-size: 1.2rem;
    line-height: 1.46rem;
  }

  .feedbacks__container .slick-dots {
    margin-top: 2.4rem;
  }
}

@media (max-width: 576px) {
  .feedbacks__title {
    margin-bottom: 2.4rem;
    font-size: 3.2rem;
    line-height: 3.9rem;
  }

  .feedbacks__container .slick-list {
    width: 100%;
  }
}