.gallery {
  padding: 4.7rem 6rem;
  margin-top: 8rem;
  width: 100%;
  max-width: 144rem;
  height: fit-content;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.gallery_type_small {
  width: 132rem;
}

.gallery__title {
  margin: 0;
  color: #fff;
  font-size: 4.5rem;
  font-weight: 500;
  line-height: 5.5rem;
  text-align: left;
}

.gallery__text {
  margin: 2.4rem 0 0;
  color: #fff;
  font-size: 3.2rem;
  font-weight: 500;
  line-height: 4.2rem;
  text-align: left;
}

.gallery__buttons {
  margin-top: 4.8rem;
  display: flex;
  gap: 1rem;
}

.gallery__button {
  padding: 1.5rem 3rem;
  color: #fff;
  position: relative;
  width: fit-content;
  height: fit-content;
  background-color: #2d2d2d66;
  backdrop-filter: blur(15px);
  border-radius: 1.5rem;
  box-sizing: border-box;
  border: 0;
  z-index: 1;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 2.2rem;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s linear;
}

.gallery__button:hover {
  background: rgba(255, 255, 255, 0.1);
}

.gallery__button_active {
  background: rgba(255, 255, 255, 0.2);
}

.gallery__button_active:hover {
  background: rgba(255, 255, 255, 0.2);
}

.gallery__list {
  margin-top: 4.8rem;
  width: 100%;
  display: grid;
  grid-auto-rows: 52rem;
  grid-template-columns: repeat(3, 1fr);
  gap: 4.5rem;
}

.gallery__list_type_small {
  grid-auto-rows: 44.8rem;
}

.project {
  overflow: hidden;
  border-radius: 2.4rem;
  grid-column: span 1;
}

.project:hover .project__image {
  transform: scale(1.1);
}

.project__link {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: end;
  text-decoration: none;
  cursor: pointer;
}

.project__link::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 0.6) 100%
  );
  z-index: 1;
}

.project__image {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
  transition: all 0.4s ease-out;
}

.project_type_big {
  grid-column: span 2;
}

.project_type_full {
  grid-column: span 3;
}

.project__info {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  width: 100%;
  padding: 2.4rem;
  z-index: 2;
}

.project__title {
  margin: 0;
  color: #fff;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 2.2rem;
  text-align: left;
}

.project__text {
  margin: 0;
  color: #fff;
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 3.6rem;
  text-align: left;
}

.gallery__link {
  align-self: center;
  margin-top: 8rem;
  text-decoration: none;
}

@media (min-width: 1441px) {
  .gallery {
    max-width: 1440px;
    padding: 47px 60px;
    margin-top: 80px;
  }

  .gallery__title {
    font-size: 45px;
    line-height: 55px;
  }

  .gallery__text {
    margin: 24px 0 0;
    font-size: 32px;
    line-height: 42px;
  }

  .gallery__buttons {
    margin-top: 48px;
    gap: 10px;
  }

  .gallery__button {
    padding: 15px 30px;
    border-radius: 15px;
    font-size: 18px;
    line-height: 22px;
  }

  .gallery__list {
    margin-top: 48px;
    grid-auto-rows: 520px;
    gap: 45px;
  }

  .project {
    border-radius: 24px;
  }

  .project__info {
    gap: 12px;
    padding: 24px;
  }

  .project__title {
    font-size: 18px;
    line-height: 22px;
  }

  .project__text {
    font-size: 24px;
    line-height: 36px;
  }
}

@media (max-width: 1024px) {
  .gallery {
    margin-top: 7.2rem;
    padding: 6.7rem 2.4rem;
  }

  .gallery_type_small {
    width: 100%;
  }

  .gallery__title {
    font-size: 3.2rem;
    line-height: 3.9rem;
  }

  .gallery__text {
    font-size: 2.4rem;
    line-height: 2.9rem;
  }

  .gallery__buttons {
    margin-top: 5.8rem;
  }

  .gallery__button {
    padding: 1rem 2rem;
    font-size: 1.4rem;
    line-height: 1.7rem;
  }

  .gallery__list {
    gap: 2.4rem;
    grid-auto-rows: 28rem;
  }

  .project__title {
    font-size: 1.4rem;
    line-height: 1.7rem;
  }

  .project__text {
    font-size: 1.8rem;
    line-height: 2.4rem;
  }

  .gallery__link {
    margin-top: 4.8rem;
  }
}

@media (max-width: 576px) {
  .gallery {
    padding: 2.4rem 1.2rem;
  }

  .gallery__title {
    font-size: 2.8rem;
  }

  .gallery__text {
    font-size: 1.8rem;
  }

  .gallery__buttons {
    flex-direction: column;
    margin-top: 2.4rem;
  }

  .gallery__button {
    padding: 1rem 2rem;
    border-radius: 1rem;
  }

  .gallery__list {
    margin-top: 2.4rem;
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
  }

  .project {
    width: 100%;
    height: 40rem;
  }

  .project_type_big {
    grid-column: span 1;
  }

  .project__title {
    font-size: 1.4rem;
    line-height: 1.95rem;
  }

  .project__text {
    font-size: 1.8rem;
    line-height: 2.9rem;
  }
}
