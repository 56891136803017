.main {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  scroll-snap-type: y mandatory;
}

.main__manuscript {
  border-radius: 2.4rem;
  margin: 5rem 0 7rem;
  width: 60.9rem;
  height: 8.1rem;
  object-fit: contain;
}

.main__about {
  margin-top: 8rem;
  width: 132rem;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  margin-bottom: 6rem;
}

.main__about_type_aparts {
  width: 109.6rem;
}

.main__title {
  margin: 0;
  color: white;
  font-weight: 500;
  font-size: 3.2rem;
  line-height: 4.2rem;
}

.main__text {
  margin: 0;
  color: white;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 2.4rem;
}

@media (min-width: 1441px) {
  .main__manuscript {
    margin: 50px 0 70px;
    width: 609px;
    height: 81px;
  }
}

@media (max-width: 1024px) {
  .main__manuscript {
    margin: 4.8rem 0;
    width: 40.6rem;
    height: 5.4rem;
  }

  .main__about {
    padding: 0 4.8rem;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 4.8rem;
  }

  .main__title {
    font-size: 2.4rem;
    line-height: 2.9rem;
  }

  .main__text {
    font-size: 1.6rem;
    line-height: 1.95rem;
  }
}

@media (max-width: 576px) {
  .main__manuscript {
    width: 30.5rem;
    height: 4rem;
  }

  .main__about {
    padding: 0 1.8rem;
    margin-bottom: 1.8rem;
  }

  .main__title {
    font-size: 1.8rem;
    line-height: 2.2rem;
  }

  .main__text {
    font-size: 1.4rem;
    line-height: 1.7rem;
  }
}
