.container {
  background: linear-gradient(
    101.42deg,
    #595959 -0.04%,
    rgba(97, 97, 97, 0.27) 94.2%
  );
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  position: relative;
}

.container::before {
  content: "";
  display: block;
  position: absolute;
  left: -15rem;
  top: 0;
  height: 100%;
  width: 15rem;
  background: linear-gradient(
    to right,
    transparent 0%,
    #ffffff17 50%,
    transparent 100%
  );
  animation: load 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
}

@keyframes load {
  from {
    left: -15rem;
  }
  to {
    left: 100%;
  }
}

.containerLoaded {
  background: transparent;
}

.containerLoaded::before {
  animation: none;
}

.image {
  margin: 0 !important;
  padding: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.3s ease-in-out;
}

.imageLoaded {
  opacity: 1;
}

.imageLoaded .container {
  background: transparent;
}
