.video {
  position: relative;
  align-self: center;
  width: 100%;
  max-width: 78.2rem;
  aspect-ratio: 16 / 9;
  background-color: black;
  border-radius: 2.4rem;
  overflow: hidden;
}

.video video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.video__button {
  padding: 0;
  width: 14rem;
  height: 10.2rem;
  position: absolute;
  top: calc(50% - 5.1rem);
  left: calc(50% - 7rem);
  background-color: rgba(169, 12, 75, 0.6);
  border-radius: 2.4rem;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  opacity: 1;
  transition: transform 0.2s linear, opacity 1s linear;
}

.video__button__disabled {
  opacity: 0;
  cursor: default;
}

.video__button:before {
  content: "";
  width: 0;
  height: 0;
  border-top: 2rem solid transparent;
  border-left: 4rem solid #fff;
  border-bottom: 2rem solid transparent;
}

.video__button:hover {
  transform: scale(1.05);
}

@media (max-width: 1024px) {
  .video {
    max-width: 47.2rem;
  }

  .video__button {
    width: 6.8rem;
    height: 4.8rem;
    top: calc(50% - 2.4rem);
    left: calc(50% - 3.4rem);
    border-radius: 1.6rem;
  }

  .video__button:before {
    border-top: 1rem solid transparent;
    border-left: 2rem solid #fff;
    border-bottom: 1rem solid transparent;
  }
}

@media (max-width: 576px) {
  .video {
    max-width: 100%;
    height: 17.4rem;
  }
}
