.policy {
  padding: 4.7rem 0;
  margin-top: 8rem;
  width: 109.6rem;
  display: flex;
  flex-direction: column;
  gap: 4.6rem;
}

.policy__title {
  margin: 0;
  color: #fff;
  font-size: 4.5rem;
  font-weight: 500;
  line-height: 5.5rem;
  text-align: left;
  width: 100%;
}

.policy__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2.4rem; 
}

.policy__subtitle {
  margin: 0;
  width: 100%;
  color: #fff;
  font-size: 3.2rem;
  font-weight: 500;
  line-height: 4.2rem;
  text-align: left;
}

.policy__text {
  margin: 0;
  width: 100%;
  color: #fff;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 2.2rem;
  text-align: left;
}

.policy__list {
  padding: 0;
  padding-left: 3rem;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.policy__element {
  margin: 0;
  color: #fff;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 2.2rem;
  text-align: left;
}

@media (min-width: 1441px) {
  .policy {
    padding: 47px 0;
    margin-top: 80px;
    width: 1096px;
    gap: 46px;
  }
  
  .policy__title {
    font-size: 45px;
    line-height: 55px;
  }
  
  .policy__container {
    gap: 24px; 
  }
  
  .policy__subtitle {
    font-size: 32px;
    line-height: 42px;
  }
  
  .policy__text {
    font-size: 18px;
    line-height: 22px;
  }
  
  .policy__list {
    padding-left: 30px;
  }
  
  .policy__element {
    font-size: 18px;
    line-height: 22px;
  }
}

@media (max-width: 1024px) {
  .policy {
    padding: 2.4rem;
    box-sizing: border-box;
    width: 100%;
  }

  .policy__title {
    font-size: 3.2rem;
    line-height: 3.9rem;
  }

  .policy__subtitle {
    font-size: 2.4rem;
    line-height: 2.9rem;
  }

  .policy__text, .policy__element {
    font-size: 1.6rem;
    line-height: 1.95rem;
  }
}

@media (max-width: 576px) {
  .policy {
    padding: 2.4rem 1.2rem;
    box-sizing: border-box;
    width: 100%;
  }

  .policy__title {
    font-size: 2.4rem;
    line-height: 2.9rem;
  }

  .policy__subtitle {
    font-size: 1.8rem;
    line-height: 2.2rem;
  }

  .policy__text, .policy__element {
    font-size: 1.4rem;
    line-height: 1.7rem;
  }
}