.form {
  padding: 2.4rem;
  box-sizing: border-box;
  width: 100%;
  height: fit-content;
  flex-shrink: 0;
  background-color: rgba(66, 66, 66, 0.4);
  border-radius: 2.4rem;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
}

.form__title {
  margin: 0;
  width: 100%;
  color: #fff;
  font-size: 3.2rem;
  font-weight: 500;
  line-height: 3.9rem;
  text-align: left;
}

.form__section {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.form__input-title {
  margin: 0;
  color: rgba(131, 131, 131, 1);
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.7rem;
  text-align: left;
}

.form__buttons {
  display: flex;
  gap: 1.5rem;
}

.form__button {
  position: relative;
  color: rgba(131, 131, 131, 1);
  padding: 0;
  border: 0;
  outline: none;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 2.2rem;
  text-align: left;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  transition: all .2s linear;
}

.form__button_active {
  color: #fff;
  transform: scale(1.02);
  border-bottom: 0.2rem solid rgba(230, 0, 126, 1);
  cursor: default;
}

.form__button::after {
  content: "";
  width: 0%;
  height: 0.2rem;
  background: #fff;
  transition: all 0.10s linear;
}

.form__button::before {
  content: "";
  position: absolute;
  bottom: 0;
  width: 0%;
  height: 0.2rem;
  background: rgba(230, 0, 126, 1);
  transition: all 0.3s linear;
}

.form__button:hover::after {
  align-self: flex-start;
  width: 100%;
}

.form__button:hover::before {
  align-self: flex-start;
  width: 100%;
}

.form__button_active::after, .form__button_active::before {
  display: none;
}

.form__telegram {
  height: 0;
  overflow-y: hidden;
  margin-top: -1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  transition: all .4s ease-out;
}

.form__telegram_active {
  height: 10rem;
}

.form__or {
  margin: 0;
  color: rgba(131, 131, 131, 1);
  text-align: center;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.7rem;
  text-align: center;
}

@media (min-width: 1441px) {
  .form {
    padding: 24px;
    border-radius: 24px;
    gap: 24px;
  }
  
  .form__title {
    font-size: 32px;
    line-height: 39.01px;
  }
  
  .form__section {
    gap: 15px;
  }
  
  .form__input-title {
    font-size: 14px;
    line-height: 17.07px;
  }
  
  .form__buttons {
    gap: 15px;
  }
  
  .form__button {
    font-size: 18px;
    line-height: 21.94px;
    gap: 4px;
  }
  
  .form__button_active {
    border-bottom: 2px solid rgba(230, 0, 126, 1);
  }
  
  .form__button::after {
    height: 2px;
  }
  
  .form__button::before {
    height: 2px;
  }
  
  .form__telegram {
    margin-top: -15px;
    gap: 15px;
  }
  
  .form__telegram_active {
    height: 100px;
  }
  
  .form__or {
    font-size: 14px;
    line-height: 17.07px;
  }
}

@media (max-width: 576px) {
  .form__title {
    font-size: 1.8rem;
    line-height: 2.2rem;
  }

  .form__section:last-of-type {
    margin-top: -1.9rem;
  }

  .form__buttons {
    gap: 1.3rem;
  }
}