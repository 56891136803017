.promo {
  content-visibility: auto;
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.promo_type_cont {
  height: 61.6rem;
  justify-content: end;
}

.promo_type_ren {
  height: 70rem;
}

.promo__image {
  width: 100%;
  height: 100%;
  position: absolute !important;
  top: 0;
  left: 0;
  object-fit: cover;
}

.promo__image_type_cont {
  object-position: 0 -6rem;
}

.promo__image_type_ren {
  object-position: 0 -1rem;
}

.promo__image_type_sup {
  object-position: 0 -1rem;
}

.promo::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1;
}

.promo_type_cont::before {
  background-color: rgba(0, 0, 0, 0.5);
}

.promo__info {
  margin-left: 17.2rem;
  width: 79.8rem;
  display: flex;
  flex-direction: column;
  gap: 4rem;
}

.promo__info_type_cont {
  margin-bottom: 11rem;
}

.promo__info_type_sup {
  margin-bottom: 22rem;
}

.promo__container {
  position: relative;
  z-index: 3;
  width: 100%;
  max-width: 144rem;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  transition: all 0.2s linear;
}

.promo__title {
  font-family: "Montserrat", Arial, sans-serif;
  width: 79.8rem;
  margin: 0;
  font-size: 6rem;
  font-weight: 500;
  line-height: 8rem;
  text-align: left;
  color: #fff;
  text-transform: uppercase;
}

.promo__buttons {
  display: flex;
  gap: 1.5rem;
}

.promo__gradient {
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 18rem;
  background: rgb(28, 28, 28);
  background: linear-gradient(
    0deg,
    rgba(28, 28, 28, 1) 10%,
    rgba(0, 0, 0, 0) 100%
  );
  z-index: 2;
}

@media (min-width: 1441px) {
  .promo__info {
    margin-left: 172px;
    width: 728px;
    gap: 40px;
  }

  .promo__container {
    max-width: 1440px;
    gap: 40px;
  }

  .promo__title {
    width: 728px;
    font-size: 60px;
    line-height: 80px;
  }

  .promo__buttons {
    gap: 15px;
  }

  .promo__gradient {
    height: 180px;
  }
}

@media (max-width: 1024px) {
  .promo_type_cont {
    height: 65rem;
    justify-content: center;
  }

  .promo_type_ren {
    height: 45.4rem;
    background-color: #e8e8e8;
  }

  .promo__image_type_cont {
    object-position: center 0;
  }

  .promo__image_type_sup {
    object-position: -26rem 0;
  }

  .promo__info_type_sup {
    margin-bottom: 0;
  }

  .promo__image_type_ren {
    object-position: 0 2rem;
  }

  .promo__info {
    margin-left: 2.4rem;
    width: 48.6rem;
    gap: 3rem;
  }

  .promo__info_type_cont {
    margin-bottom: 0;
  }

  .promo__container {
    max-width: 1440px;
    gap: 4rem;
  }

  .promo__title {
    font-size: 4rem;
    line-height: 6rem;
    width: 52.6rem;
  }

  .promo__buttons {
    gap: 1.5rem;
  }

  .promo__gradient {
    height: 12rem;
  }
}

@media (max-width: 576px) {
  .promo_type_cont {
    height: 41.8rem;
    justify-content: end;
    background-color: #cfcfcf;
  }

  .promo__image_type_cont {
    object-position: -7rem 8rem;
  }

  .promo__image_type_ren {
    object-position: center 6rem;
    transform: scale(1.2);
  }

  .promo__image_type_sup {
    object-position: -26rem 3rem;
  }

  .promo__info_type_sup {
    margin-bottom: 6rem;
  }

  .promo__info {
    margin-left: 2.4rem;
    width: 29.2rem;
    gap: 3rem;
  }

  .promo__info_type_cont {
    margin-bottom: 6rem;
    width: 80% !important;
  }

  .promo__container {
    max-width: 1440px;
    gap: 4rem;
  }

  .promo__title {
    font-size: 2.4rem;
    line-height: 3.2rem;
    width: 32.2rem;
  }

  .promo__buttons {
    flex-direction: column;
  }

  .promo__gradient {
    height: 12rem;
  }
}

@media (max-width: 450px) {
  .promo_type_cont {
    width: 100%;
    overflow: hidden;
  }
  .promo__image_type_cont {
    object-position: -11.5rem 7rem;
  }
}
